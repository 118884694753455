<template>
  <v-container
    fluid
    fill-height
    class="login-container"
    :style="{
      background: `center/cover url(${require('@/assets/bg_login.jpg')})`,
    }"
  >
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4 class="">
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Inloggen - Eduforce-orderbeheer</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-8">
            <v-form>
              <v-text-field
                class="mt-0"
                prepend-icon="mdi-account"
                type="text"
                v-model="user.email"
                label="E-mailadres"
                :error="form.user.fields.email.error"
                :error-messages="form.user.fields.email.error_messages"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                prepend-icon="mdi-lock"
                type="password"
                v-model="user.password"
                label="Wachtwoord"
                :error="form.user.fields.password.error"
                :error-messages="form.user.fields.password.error_messages"
                outlined
                dense
              ></v-text-field>
              <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                  text
                  dark
                  class="mr-3"
                  outlined
                  rounded
                  color="primary"
                  :to="{ name: 'UserPasswordRequest' }"
                  >Wachtwoord wijzigen</v-btn
                >
                <v-btn
                  dark
                  color="primary"
                  rounded
                  :loading="form.user.submitting"
                  v-on:click.stop="submit"
                  >Inloggen</v-btn
                >
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import UserService from "@/services/UserService";

export default {
  props: ["id"],
  data() {
    return {
      user: {
        email: null,
        password: null,
      },
      form: {
        user: {
          submitting: false,
          fields: {
            email: { error: false, error_messages: [] },
            password: { error: false, error_messages: [] },
          },
        },
      },
    };
  },
  methods: {
    submit() {
      this.form.user.submitting = true;
      let formFields = this.form.user.fields;
      this.resetValidationErrors(formFields);
      UserService.login(this.user)
        .then(({ data }) => {
          this.notify(data.message);
          this.$store.dispatch("user/set", data.user).then(() => {
            this.$router.push({ name: "SalesOrderIndex" });
            this.form.user.submitting = false;
          });
        })
        .catch((err) => {
          this.notifyErr(err);
          if (err.response.status === 422) {
            this.showValidationErrors(formFields, err.response.data.errors);
          }
          this.form.user.submitting = false;
        });
    },
  },
};
</script>

<style>
.login-container {
}
</style>
