<template>
  <transition name="module-fade">
    <div class="module" v-if="salesOrder.items">
      <div class="d-none" :class="classIndexPanel">
        <div class="m-p-header">
          <v-app-bar class="" flat color="primary" dark>
            <v-toolbar-title v-if="false">Orders </v-toolbar-title>
            <Search></Search>
            <v-spacer></v-spacer>
            <v-btn
              rounded
              outlined
              @click.stop="synchronise()"
              :loading="synchroniseLoading"
              ><v-icon dark left>mdi-sync</v-icon>Synchroniseren</v-btn
            >
            <v-btn rounded outlined class="ml-3" v-if="false"
              ><v-icon dark left>mdi-microsoft-excel</v-icon>Exporteren</v-btn
            >
          </v-app-bar>
        </div>
        <div class="m-p-content">
          <v-data-table
            :headers="headers"
            :items="salesOrder.items"
            :options.sync="options"
            :server-items-length="salesOrder.totalItems"
            :loading="loading"
            @click:row="openSalesOrder"
            item-key="increment_id"
            :must-sort="false"
            :multi-sort="true"
            sort-by="increment_id"
            :sort-desc="true"
          >
            <template v-slot:item.created_at="{ item }">
              {{ formatDate(item.created_at) }}
            </template>
            <template v-slot:item.amount="{ item }">
              {{ formatCurrency(item.amount) }}
            </template>
            <template v-slot:item.date="{ item }">
              {{ formatDate(item.date) }}
            </template>
            <template v-slot:item.status_title="{ item }">
              <v-chip :color="item.status_color" dark>
                {{ item.status_title }}
              </v-chip>
            </template>
            <template v-slot:item.shipment_status_title="{ item }">
              <v-chip :color="item.shipment_status_color" dark>
                {{ item.shipment_status_title }}
              </v-chip>
            </template>
            <template v-slot:item.allsolutions_status_title="{ item }">
              <v-chip :color="item.allsolutions_status_color" dark>
                {{ item.allsolutions_status_title }}
              </v-chip>
            </template>
          </v-data-table>
        </div>
      </div>
      <div class="d-none" :class="classShowPanel">
        <Show :salesOrderId="salesOrderId" @close="close" />
      </div>
    </div>
  </transition>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import Show from "@/views/sales_order/Show";
import Search from "@/views/sales_order/components/Search";
import JobService from "@/services/JobService";

export default {
  props: ["salesOrderId"],
  components: {
    Show,
    Search,
  },
  data() {
    return {
      loading: true,
      search: "",
      options: {},
      synchroniseLoading: false,
      headers: [
        {
          text: "ID",
          align: "left",
          sortable: true,
          value: "increment_id",
        },
        {
          text: "Klant",
          align: "left",
          sortable: true,
          value: "customer_name",
        },
        {
          text: "Datum",
          align: "left",
          sortable: true,
          value: "date",
        },
        {
          text: "Bedrag",
          align: "right",
          sortable: true,
          value: "amount",
        },
        {
          text: "Besteld",
          align: "right",
          sortable: true,
          value: "quantity_ordered",
        },
        {
          text: "Verzonden",
          align: "right",
          sortable: true,
          value: "quantity_shipped",
        },
        {
          text: "Orderstatus",
          align: "left",
          sortable: true,
          value: "status_title",
        },
        {
          text: "Verzendstatus",
          align: "left",
          sortable: true,
          value: "shipment_status_title",
        },
        {
          text: "AllSolutions exportstatus",
          align: "left",
          sortable: true,
          value: "allsolutions_status_title",
        },
      ],
    };
  },
  computed: {
    ...mapState(["salesOrder", "salesOrderStatus", "salesOrderShipmentStatus"]),
    classIndexPanel() {
      return { "d-block": this.salesOrderId === undefined };
    },
    classShowPanel() {
      return { "d-block": this.salesOrderId !== undefined };
    },
    activeFilter() {
      let activeFilter = [];
      console.log(this.salesOrder);
      if (this.salesOrder.filter.shipment_status_id) {
        let salesOrderShipmentStatus = this.salesOrderShipmentStatus.filter(
          (status) => status.id === this.salesOrder.filter.shipment_status_id
        )[0];
        activeFilter.push({
          field: "verzendstatus",
          value: salesOrderShipmentStatus,
        });
      }
      return activeFilter;
    },
  },
  watch: {
    options: {
      deep: true,
      handler(newValue, oldValue) {
        console.log("watch options", newValue, oldValue);
        console.log("this.options", this.options);
        //if (!oldValue) return;
        //if (this.options.itemsPerPage == -1) this.options.itemsPerPage = 1000;
        this.$store.dispatch("salesOrder/setOptions", this.options);
        this.index();
      },
    },
  },
  methods: {
    close() {
      this.$router.push({
        name: "SalesOrderIndex",
      });
    },
    openSalesOrder(item) {
      this.$router.push({
        name: "SalesOrderIndex",
        params: { salesOrderId: item.id },
      });
    },
    doSearch: _.debounce(function () {
      console.log("doSearch()");
      this.options.page = 1;
      this.$store.dispatch("salesOrder/setSearch", this.search);
      this.index();
    }, 500),
    index() {
      this.loading = true;
      this.$store.dispatch("salesOrder/index", this.options).then(() => {
        this.loading = false;
      });
    },
    printPickList(id) {
      console.log(id);
      alert("todo");
    },
    printShippingLabel(id) {
      console.log(id);
      alert("todo");
    },
    synchronise() {
      this.synchroniseLoading = true;
      JobService.execute(3)
        .then(({ data }) => {
          this.notify(data.message);
          this.index();
          JobService.execute(4)
            .then(({ data }) => {
              this.notify(data.message);
              this.index();
              this.synchroniseLoading = false;
            })
            .catch((err) => {
              this.notifyErr(err);
              this.synchroniseLoading = false;
            });
        })
        .catch((err) => {
          this.notifyErr(err);
          this.synchroniseLoading = false;
        });
    },
  },
};
</script>

<style>
tr {
  cursor: pointer;
}
</style>
