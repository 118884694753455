var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.item)?_c('div',[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.item.increment_id)+" ")]),_c('div',{staticClass:"ml-16"},[_c('Status')],1),_c('div',{staticClass:"ml-16"},[_c('ShipmentStatus')],1),_c('div',{staticClass:"ml-16"},[_c('AllsolutionsStatus')],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","disabled":_vm.salesOrder.prevItemId === null,"to":{
        name: 'SalesOrderIndex',
        params: { salesOrderId: _vm.salesOrder.prevItemId },
      }}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.salesOrder.nextItemId === null,"to":{
        name: 'SalesOrderIndex',
        params: { salesOrderId: _vm.salesOrder.nextItemId },
      }}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('v-tabs',{attrs:{"vertical":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"tab1"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-information-outline")]),_vm._v(" Info ")],1),_c('v-tab',{key:"tab2"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-cart-outline")]),_c('v-badge',{attrs:{"color":_vm.shipmentsStatusColor,"content":_vm.item.quantity_ordered}},[_vm._v(" Producten ")]),(false)?_c('v-chip',{staticClass:"ml-2",attrs:{"x-small":"","outlined":""}},[_vm._v(_vm._s(_vm.item.quantity_ordered)+" ")]):_vm._e()],1),_c('v-tab',{key:"tab3"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-truck-cargo-container")]),_c('v-badge',{attrs:{"color":_vm.shipmentsStatusColor,"content":_vm.shipmentLength.toString()}},[_vm._v(" Zendingen ")])],1),_c('v-tab',{key:"tab4"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-database-import-outline")]),_vm._v(" Magento data ")],1),_c('v-tab',{key:"tab5"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-database-export-outline")]),_vm._v(" AllSolutions data ")],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"item1"},[_c('Info',{attrs:{"salesOrder":_vm.item}})],1),_c('v-tab-item',{key:"item2"},[_c('Products',{attrs:{"salesOrder":_vm.item}})],1),_c('v-tab-item',{key:"item3"},[_c('ShipmentIndex',{attrs:{"salesOrder":_vm.item}})],1),_c('v-tab-item',{key:"item4"},[_c('Import',{attrs:{"sales-order":_vm.item}})],1),_c('v-tab-item',{key:"item5"},[_c('Export',{attrs:{"sales-order":_vm.item}})],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }