<template>
  <div>
    <div class="text-h2 chart--text">{{ total }}</div>
    <div class="text-h6">{{ title }}</div>
  </div>
</template>

<script>
import ChartService from "@/services/ChartService";

export default {
  props: ["salesOrderStatusId", "title"],
  data() {
    return {
      total: 0,
    };
  },
  mounted() {
    ChartService.totalSalesOrdersStatus(this.salesOrderStatusId).then(
      ({ data }) => {
        this.total = data.total;
      }
    );
  },
};
</script>

<style>
.title {
  font-size: 25px;
  font-weight: bold;
}
</style>
