import ConfigService from "@/services/ConfigService";

const state = {
  loaded: false,
  appName: null,
  appEnv: null,
  appVersion: null,
  urlFrontend: null,
  urlStorage: null,
};

const mutations = {
  SET(state, config) {
    state.loaded = true;
    state.appName = config.appName;
    state.appVersion = config.appVersion;
    state.env = config.env;
    state.urlFrontend = config.urlFrontend;
    state.urlStorage = config.urlStorage;
  },
};

const actions = {
  get({ commit }) {
    return ConfigService.get().then((response) => {
      commit("SET", response.data.config);
    });
  },
};

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
};
