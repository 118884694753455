<template>
  <div class="m-p-content" v-if="loaded">
    <v-toolbar flat color="#ffffff">
      <div>
        <v-toolbar-title>Product gegevens</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        v-if="id"
        class="ma-2"
        @click.stop="destroy(id)"
        outlined
        rounded
        color="white"
      >
        <v-icon left>mdi-delete-outline</v-icon>
        Verwijder
      </v-btn>
      <v-btn
        class="ma-2 primary--text"
        rounded
        color="white"
        :loading="form.product.submitting"
        v-on:click="submit"
        ><v-icon dark left>mdi-content-save</v-icon> Opslaan</v-btn
      >
    </v-toolbar>
    <v-container class="pa-4">
      <v-row>
        <v-col class="md-6">
          <v-text-field
            v-model="product.shipment_weight"
            label="Verzendgewicht"
            required
            :error="form.product.fields.shipment_weight.error"
            :error-messages="form.product.fields.shipment_weight.error_messages"
            outlined
            dense
            type="number"
            :min="0"
            :max="100"
          ></v-text-field>
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="available_from"
                label="Vasthouden tot"
                v-bind="attrs"
                v-on="on"
                :error="form.product.fields.available_from.error"
                :error-messages="
                  form.product.fields.available_from.error_messages
                "
                outlined
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="product.available_from"
              no-title
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
          <v-text-field
            v-model="product.location"
            label="Magazijn locatie"
            required
            :error="form.product.fields.location.error"
            :error-messages="form.product.fields.location.error_messages"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col class="md-6"> </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
//import _ from "lodash";
import ProductService from "@/services/ProductService";

export default {
  components: {},
  props: ["id"],
  data() {
    return {
      loaded: false,
      menu1: false,
      product: {
        active: 0,
        shipment_weight: 0,
        available_from: "",
        location: "",
      },
      form: {
        product: {
          submitting: false,
          fields: {
            active: { error: false, error_messages: [] },
            shipment_weight: { error: false, error_messages: [] },
            available_from: { error: false, error_messages: [] },
            location: { error: false, error_messages: [] },
          },
        },
      },
    };
  },
  computed: {
    available_from() {
      console.log(this.product.available_from);
      return this.formatDateLong(this.product.available_from);
    },
  },
  methods: {
    submit() {
      this.form.product.submitting = true;
      let formFields = this.form.product.fields;
      this.resetValidationErrors(formFields);
      const apiCall = this.id
        ? ProductService.update(this.id, this.product)
        : ProductService.store(this.product);
      apiCall
        .then(({ data }) => {
          this.notify(data.message);
          this.$router.push({ name: "ProductIndex" });
          this.form.product.submitting = false;
        })
        .catch((err) => {
          this.notifyErr(err);
          if (err.response.status === 422) {
            this.showValidationErrors(formFields, err.response.data.errors);
          }
          this.form.product.submitting = false;
        });
    },
    destroy(id) {
      this.$root.$confirm
        .open("Verwijderen", "Weet je het zeker?")
        .then((confirm) => {
          if (!confirm) return;
          ProductService.destroy(id)
            .then(({ data }) => {
              this.notify(data.message);
              this.$router.push({ name: "ProductIndex" });
              this.$store.dispatch("product/list");
            })
            .catch((err) => {
              this.notifyErr(err);
            });
        });
    },
  },
  mounted() {
    ProductService.edit(this.id)
      .then(({ data }) => {
        console.log("data", data);
        this.product = data.product;
        this.loaded = true;
      })
      .catch((err) => {
        this.notifyErr(err);
      });
  },
};
</script>
