<template>
  <v-btn rounded outlined @click.stop="execute()" :loading="submitting"
    ><v-icon dark left>{{ icon }}</v-icon
    >{{ title }}</v-btn
  >
</template>

<script>
import JobService from "@/services/JobService";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    salesOrder: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      submitting: false,
    };
  },
  methods: {
    execute() {
      if (this.submitting) return;
      this.submitting = true;
      JobService.execute(this.id, this.data)
        .then(({ data }) => {
          this.notify(data.message);
          this.$emit("refresh");
          this.submitting = false;
        })
        .catch((err) => {
          this.notifyErr(err);
          this.submitting = false;
        });
    },
  },
  computed: {
    data() {
      if (this.salesOrder) {
        return { salesOrderId: this.salesOrder.id };
      }
      return {};
    },
  },
};
</script>
