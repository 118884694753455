<template>
  <div style="background-color: white; border-radius: 4px">
    <v-alert text outlined :dismissible="false" :type="alert.type">
      {{ alert.message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    alert: {
      type: Object,
      required: true,
    },
  },
};
</script>
