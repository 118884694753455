import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_HOST_API + "/api/shipment",
  withCredentials: true,
  headers: {
    Accept: "application/json",
  },
});

apiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.data.status == 401) {
      //
    }
    return Promise.reject(error);
  }
);

export default {
  index(data) {
    return apiClient.get(null, { params: data });
  },
  create(data) {
    return apiClient.get("/create", { params: data });
  },
  store(data) {
    return apiClient.post(null, data);
  },
  show(id) {
    return apiClient.get("/" + id);
  },
  edit(data) {
    return apiClient.get("/" + data.id + "/edit", {
      params: { sales_order_id: data.sales_order_id },
    });
  },
  update(id, data) {
    return apiClient.put("/" + id, data);
  },
  destroy(id) {
    return apiClient.delete("/" + id);
  },
  status(id, data) {
    return apiClient.post("/" + id + "/status", data);
  },
  picklistPdf(id) {
    return apiClient.post(
      "/" + id + "/picklist-pdf",
      {},
      { responseType: "blob" }
    );
  },
  picklistsPdf(data) {
    return apiClient.post("/picklists-pdf", data, { responseType: "blob" });
  },
  labelPdf(id) {
    return apiClient.post("/" + id + "/label-pdf");
  },
  labelsPdf(data) {
    return apiClient.post("/labels-pdf", data, { responseType: "blob" });
  },
  confirmation(id) {
    return apiClient.post("/" + id + "/confirmation");
  },
  confirmations(data) {
    return apiClient.post("/confirmations", data);
  },
  updateStatus(id, data) {
    return apiClient.put("/" + id + "/status", data);
  },
};
