<template>
  <transition name="module-fade">
    <div class="module" v-if="loaded">
      <div class="m-primary">
        <div class="m-p-header">
          <v-toolbar flat dark color="primary">
            <v-btn icon :to="{ name: 'EmailTemplateIndex' }">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title class=""
              >{{ emailTemplate.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              v-if="id && false"
              class="ma-2"
              @click.stop="destroy(id)"
              outlined
              rounded
              color="white"
            >
              <v-icon left>mdi-delete-outline</v-icon>
              Verwijder
            </v-btn>
            <v-btn
              class="ma-2 primary--text"
              rounded
              color="white"
              :loading="form.emailTemplate.submitting"
              v-on:click="submit"
              ><v-icon dark left>mdi-content-save</v-icon> Opslaan</v-btn
            >
          </v-toolbar>
        </div>
        <div class="m-p-body">
          <div class="m-p-content pa-5">
            <v-container class="emailTemplate-edit">
              <v-row>
                <v-col class="md-6">
                  <v-text-field
                    v-model="emailTemplate.title"
                    label="E-mail template"
                    required
                    :error="form.emailTemplate.fields.title.error"
                    :error-messages="
                      form.emailTemplate.fields.title.error_messages
                    "
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="emailTemplate.subject"
                    label="E-mail onderwerp"
                    required
                    :error="form.emailTemplate.fields.subject.error"
                    :error-messages="
                      form.emailTemplate.fields.subject.error_messages
                    "
                    outlined
                    persistent-hint
                    :hint="emailTemplate.subject_codes"
                    class="mb-4"
                  ></v-text-field>
                  <v-textarea
                    v-model="emailTemplate.body"
                    label="E-mail bericht"
                    required
                    :error="form.emailTemplate.fields.body.error"
                    :error-messages="
                      form.emailTemplate.fields.body.error_messages
                    "
                    outlined
                    rows="15"
                    row-height="20"
                    persistent-hint
                    :hint="emailTemplate.body_codes"
                  ></v-textarea>
                </v-col>
                <v-col class="md-6"> </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import EmailTemplateService from "@/services/EmailTemplateService";

export default {
  props: ["id"],
  data() {
    return {
      loaded: false,
      emailTemplate: {
        title: null,
        subject: null,
        body: null,
      },
      form: {
        emailTemplate: {
          submitting: false,
          fields: {
            title: { error: false, error_messages: [] },
            subject: { error: false, error_messages: [] },
            body: { error: false, error_messages: [] },
          },
        },
      },
    };
  },
  methods: {
    submit() {
      this.form.emailTemplate.submitting = true;
      let formFields = this.form.emailTemplate.fields;
      this.resetValidationErrors(formFields);
      const apiCall = this.id
        ? EmailTemplateService.update(this.id, this.emailTemplate)
        : EmailTemplateService.store(this.emailTemplate);
      apiCall
        .then(({ data }) => {
          this.notify(data.message);
          this.$router.push({ name: "EmailTemplateIndex" });
          this.form.emailTemplate.submitting = false;
        })
        .catch((err) => {
          this.notifyErr(err);
          if (err.response.status === 422) {
            this.showValidationErrors(formFields, err.response.data.errors);
          }
          this.form.emailTemplate.submitting = false;
        });
    },
    destroy(id) {
      this.$root.$confirm
        .open("Verwijderen", "Weet je het zeker?", { color: "accent" })
        .then((confirm) => {
          if (!confirm) return;
          EmailTemplateService.destroy(id)
            .then(({ data }) => {
              this.notify(data.message);
              this.$router.push({ name: "EmailTemplateIndex" });
            })
            .catch((err) => {
              this.notifyErr(err);
            });
        });
    },
  },
  mounted() {
    console.log("mounted()");
    console.log("id", this.id);
    if (this.id) {
      EmailTemplateService.edit(this.id)
        .then(({ data }) => {
          console.log("data", data);
          this.emailTemplate = data.emailTemplate;
          this.loaded = true;
        })
        .catch((err) => {
          this.notifyErr(err);
        });
    } else {
      EmailTemplateService.create()
        .then(({ data }) => {
          console.log("data", data);
          this.emailTemplate = data.emailTemplate;
          console.log("this.emailTemplate", this.emailTemplate);
          this.loaded = true;
        })
        .catch((err) => {
          this.notifyErr(err);
        });
    }
  },
};
</script>
