<template>
  <v-app>
    <v-navigation-drawer
      class="a-nav"
      app
      clipped
      color="#f6f8fc"
      :expand-on-hover="mini"
      :mini-variant="mini"
    >
      <v-list shaped class="pt-0">
        <v-list-item
          v-for="menuItem in roleMenuItems"
          :key="menuItem.title"
          :to="menuItem.link"
          color2="#041e49"
          color="primary lighten3"
          background-color="#d3e3fd"
        >
          <v-list-item-icon>
            <v-icon>{{ menuItem.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar class="a-header" app fixed clipped-left flat color="#f6f8fc">
      <v-app-bar-nav-icon color="" @click.stop="mini = !mini">
      </v-app-bar-nav-icon>
      <div class="ml-5 mr-5">
        <v-img src="@/assets/eduforce-logo.png"> </v-img>
      </div>
      <v-toolbar-title>
        {{ config.appName }}
        <span class="version">v{{ config.appVersion }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <Scan></Scan>
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <Avatar :avatar="user.avatar"></Avatar>
          </span>
        </template>
        <v-list>
          <v-list-item link>
            <v-list-item-content class="mb-1">
              <div class="d-flex">
                <div class="pl-1 mr-3 align-self-center">
                  <Avatar :avatar="user.avatar"></Avatar>
                </div>
                <div>
                  <v-list-item-title class="text-h6">
                    {{ user.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mt-1">{{
                    user.user_role_title
                  }}</v-list-item-subtitle>
                </div>
              </div>
            </v-list-item-content>
            <v-list-item-action> </v-list-item-action>
          </v-list-item>
          <v-list-item
            v-for="settingsMenuItem in settingsMenuItems"
            :key="settingsMenuItem.title"
            router
            :to="settingsMenuItem.link"
          >
            <v-list-item-action>
              <v-icon class="">{{ settingsMenuItem.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="">
                {{ settingsMenuItem.title }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action> </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main class="ce-main">
      <div>
        <router-view />
      </div>
    </v-main>
    <NotificationContainer></NotificationContainer>
    <Confirm ref="confirm"></Confirm>
  </v-app>
</template>

<script>
import NotificationContainer from "@/components/NotificationContainer.vue";
import Avatar from "@/components/Avatar.vue";
import { mapState } from "vuex";
import "vue-json-pretty/lib/styles.css";
import Scan from "@/components/Scan.vue";
import Confirm from "@/components/Confirm";

export default {
  components: { Scan, NotificationContainer, Avatar, Confirm },
  data() {
    return {
      loaded: false,
      env: process.env,
      primaryDrawer: {
        model: null,
      },
      scan: "",
      settingsMenuItems: [
        {
          title: "Gegevens wijzigen",
          icon: "mdi-account-outline",
          link: { name: "UserDetails", params: { id: 1 } },
        },
        {
          title: "Uitloggen",
          icon: "mdi-logout",
          link: { name: "UserLogout" },
        },
      ],
      drawer: true,
      mini: false,
      menuItems: [
        {
          title: "Dashboard",
          icon: "mdi-chart-bar",
          link: { name: "Dashboard" },
          role: [1, 2],
        },
        {
          title: "Orders",
          icon: "mdi-currency-eur",
          link: { name: "SalesOrderIndex" },
          role: [1, 2],
        },
        {
          title: "Zendingen",
          icon: "mdi-truck-cargo-container",
          link: { name: "ShipmentIndex" },
          role: [1, 2],
        },
        {
          title: "Producten",
          icon: "mdi-alpha-p-circle-outline",
          link: { name: "ProductIndex" },
          role: [1, 2],
        },
        {
          title: "Verzendmethoden",
          icon: "mdi-shipping-pallet",
          link: { name: "ShipmentMethodIndex" },
          role: [1],
        },
        {
          title: "E-mail templates",
          icon: "mdi-email-outline",
          link: { name: "EmailTemplateIndex" },
          role: [1],
        },
        {
          title: "Gebruikers",
          icon: "mdi-account-outline",
          link: { name: "UserIndex" },
          role: [1],
        },
        {
          title: "Taken",
          icon: "mdi-briefcase-clock-outline",
          link: { name: "JobIndex" },
          role: [1],
        },
        {
          title: "Uitloggen",
          icon: "mdi-logout",
          link: { name: "UserLogout" },
          role: [1, 2],
        },
      ],
    };
  },
  created() {
    if (!this.$store.state.user.isAuth) {
      this.$router.push({ name: "UserLogin" });
    }
    const promiseSalesOrderStatusIndex = this.$store.dispatch(
      "salesOrderStatus/index"
    );
    const promiseSalesOrderShipmentStatusIndex = this.$store.dispatch(
      "salesOrderShipmentStatus/index"
    );
    const promiseSalesOrderAllsolutionsStatusIndex = this.$store.dispatch(
      "salesOrderAllsolutionsStatus/index"
    );
    const promiseShipmentStatusIndex = this.$store.dispatch(
      "shipmentStatus/index"
    );
    const promiseShipmentMethodList = this.$store.dispatch(
      "shipmentMethod/list"
    );
    const promiseShipmentMethodCountryList = this.$store.dispatch(
      "shipmentMethodCountry/list"
    );
    Promise.all([
      promiseSalesOrderStatusIndex,
      promiseSalesOrderShipmentStatusIndex,
      promiseSalesOrderAllsolutionsStatusIndex,
      promiseShipmentStatusIndex,
      promiseShipmentMethodList,
      promiseShipmentMethodCountryList,
    ]).then(() => {
      this.loaded = true;
    });
  },
  computed: {
    ...mapState(["config", "user"]),
    roleMenuItems() {
      return this.menuItems.filter((item) => {
        return item.role.includes(this.user.user_role_id);
      });
    },
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm;
  },
};
</script>

<style lang="scss">
body .v-tab {
  min-width: 200px;
}

.version {
  font-size: 12px;
}

.ce-main {
  background: #f6f8fc;
}

.ce-main > div {
  position: relative;
  margin: 0 16px 16px;
  padding: 0;
  background: #ffffff;
  border-radius: 16px;
  overflow-y: hidden;
}

.logo {
  max-height: 100%;
}
.v-navigation-drawer .v-navigation-drawer__border {
  display: none;
}

body .theme--light.v-btn:focus::before,
body .theme--dark.v-btn--active:hover::before,
body .theme--dark.v-btn--active::before {
  opacity: 0;
}

.v-data-table {
  .v-text-field__details {
    display: none;
  }
  .v-input--dense > .v-input__control > .v-input__slot {
    margin-bottom: 0;
  }
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:last-child
  > td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.v-item-group {
  border-right: solid 1px #e1e1e1;
}
</style>
