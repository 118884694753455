import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_HOST_API + "/api/sales-order",
  withCredentials: true,
  headers: {
    Accept: "application/json",
  },
});

export default {
  index(data) {
    return apiClient.get(null, { params: data });
  },
  show(id) {
    return apiClient.get("/" + id);
  },
  getProduct(id) {
    return apiClient.get("/product/" + id);
  },
  update(id, data) {
    return apiClient.put("/" + id, data);
  },
  updateStatus(id, data) {
    return apiClient.put("/" + id + "/status", data);
  },
  updateShipmentStatus(id, data) {
    return apiClient.put("/" + id + "/shipment-status", data);
  },
  updateAllsolutionsStatus(id, data) {
    return apiClient.put("/" + id + "/allsolutions-status", data);
  },
  comments(id) {
    return apiClient.get("/" + id + "/comments");
  },
  addComment(id, data) {
    return apiClient.put("/" + id + "/comments", data);
  },
};
