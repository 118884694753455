import Vue from "vue";
import Vuex from "vuex";
import config from "@/store/modules/config.js";
import network from "@/store/modules/network.js";
import user from "@/store/modules/user.js";
import * as notification from "@/store/modules/notification.js";
import * as salesOrderStatus from "@/store/modules/sales_order_status";
import * as salesOrderShipmentStatus from "@/store/modules/sales_order_shipment_status";
import * as salesOrderAllsolutionsStatus from "@/store/modules/sales_order_allsolutions_status";
import * as shipmentStatus from "@/store/modules/shipment_status";
import * as salesOrder from "@/store/modules/sales_order";
import * as shipment from "@/store/modules/shipment";
import * as shipmentMethod from "@/store/modules/shipment_method";
import * as shipmentMethodCountry from "@/store/modules/shipment_method_country";
import * as emailTemplate from "@/store/modules/email_template";
import * as job from "@/store/modules/job";
import * as product from "@/store/modules/product";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    config: config,
    network: network,
    user: user,
    notification,
    salesOrderStatus,
    salesOrderShipmentStatus,
    salesOrderAllsolutionsStatus,
    shipmentStatus,
    salesOrder,
    shipment,
    shipmentMethod,
    shipmentMethodCountry,
    emailTemplate,
    job,
    product,
  },
});
