<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <NotificationContainer />
  </v-app>
</template>

<script>
import NotificationContainer from "@/components/NotificationContainer.vue";
export default {
  components: { NotificationContainer },
  name: "App",
  data: () => ({
    env: process.env,
  }),
  mounted() {
    console.log("NotAuthenticated.vue mounted()");
  },
};
</script>
