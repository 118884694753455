<template>
  <transition name="module-fade">
    <div class="module" v-if="loaded">
      <div class="m-primary">
        <div class="m-p-header">
          <v-toolbar flat dark color="primary">
            <v-btn icon :to="{ name: 'UserIndex' }">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ user.name }} </v-toolbar-title>
            <v-switch class="mt-6 ml-6" v-model="user.active"></v-switch>
            <v-spacer></v-spacer>
            <v-btn
              v-if="id"
              class="ma-2"
              @click.stop="destroy(id)"
              outlined
              rounded
              color="white"
            >
              <v-icon left>mdi-delete-outline</v-icon>
              Verwijder
            </v-btn>
            <v-btn
              class="ma-2 primary--text"
              rounded
              color="white"
              :loading="form.user.submitting"
              v-on:click="submit"
              ><v-icon dark left>mdi-content-save</v-icon> Opslaan</v-btn
            >
          </v-toolbar>
        </div>
        <div class="m-p-body">
          <div class="m-p-content pa-5">
            <v-container class="user-edit">
              <v-row>
                <v-col class="md-6">
                  <v-text-field
                    v-model="user.name"
                    label="Naam"
                    required
                    :error="form.user.fields.name.error"
                    :error-messages="form.user.fields.name.error_messages"
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-model="user.email"
                    label="E-mailadres"
                    required
                    :error="form.user.fields.email.error"
                    :error-messages="form.user.fields.email.error_messages"
                    outlined
                    dense
                  ></v-text-field>
                  <v-select
                    :items="[
                      { value: 1, text: 'Beheerder' },
                      { value: 2, text: 'Gebruiker' },
                    ]"
                    v-model="user.user_role_id"
                    label="Rol"
                    required
                    :error="form.user.fields.name.error"
                    :error-messages="form.user.fields.name.error_messages"
                    outlined
                    dense
                  ></v-select>
                  <p>
                    Je avatar kun je beheren op
                    <a target="_blank" href="https://gravatar.com"
                      >https://gravatar.com</a
                    >
                  </p>
                </v-col>
                <v-col class="md-6"> </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import UserService from "@/services/UserService";

export default {
  props: ["id"],
  data() {
    return {
      loaded: false,
      user: {
        active: null,
        name: null,
        email: null,
        user_role_id: null,
      },
      form: {
        user: {
          submitting: false,
          fields: {
            active: { error: false, error_messages: [] },
            name: { error: false, error_messages: [] },
            email: { error: false, error_messages: [] },
            user_role_id: { error: false, error_messages: [] },
          },
        },
      },
    };
  },
  methods: {
    submit() {
      this.form.user.submitting = true;
      let formFields = this.form.user.fields;
      this.resetValidationErrors(formFields);
      const apiCall = this.id
        ? UserService.update(this.id, this.user)
        : UserService.store(this.user);
      apiCall
        .then(({ data }) => {
          this.notify(data.message);
          this.$router.push({ name: "UserIndex" });
          this.form.user.submitting = false;
        })
        .catch((err) => {
          this.notifyErr(err);
          if (err.response.status === 422) {
            this.showValidationErrors(formFields, err.response.data.errors);
          }
          this.form.user.submitting = false;
        });
    },
    destroy(id) {
      this.$root.$confirm
        .open("Verwijderen", "Weet je het zeker?")
        .then((confirm) => {
          if (!confirm) return;
          UserService.destroy(id)
            .then(({ data }) => {
              this.notify(data.message);
              this.$router.push({ name: "UserIndex" });
              this.$store.dispatch("user/list");
            })
            .catch((err) => {
              this.notifyErr(err);
            });
        });
    },
  },
  mounted() {
    console.log("mounted()");
    console.log("id", this.id);
    if (this.id) {
      console.log("id", this.id);
      UserService.edit(this.id).then(({ data }) => {
        console.log("user data", data);
        this.user = data.user;
        this.loaded = true;
      });
    } else {
      UserService.create().then(({ data }) => {
        console.log("data", data);
        this.user = data.user;
        console.log("this.user", this.user);
        this.loaded = true;
      });
    }
  },
};
</script>
