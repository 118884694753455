<template>
  <transition name="module-fade">
    <div :class="{ module: !salesOrderId }" v-if="shipment.items">
      <ShipmentEdit
        v-if="openShipmentEdit"
        :open="openShipmentEdit"
        @close="closeShipmentEdit"
      />
      <div class="m-p-header">
        <v-toolbar
          flat
          :dark="!salesOrderId"
          :color="salesOrderId ? 'white' : 'primary'"
        >
          <template v-if="selected.length">
            <ItemsActions :items="selected" @index="index"></ItemsActions>
          </template>
          <template v-else>
            <v-toolbar-title v-if="false">Zendingen</v-toolbar-title>
            <v-text-field
              style="width: 400px"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              clear-icon="mdi-close-circle-outline"
              clearable
              label="Zoeken in zendingen"
              single-line
              hide-details
              outlined
              dense
              rounded
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn outlined rounded color="primary" @click="add()" v-if="canAdd"
              >Toevoegen</v-btn
            >
          </template>
        </v-toolbar>
      </div>
      <div class="m-p-content">
        <v-data-table
          :hide-default-footer="shipment.items.length < 4"
          :headers="headers"
          :items="shipment.items"
          :options.sync="options"
          :server-items-length="shipment.totalItems"
          :loading="loading"
          item-key="id"
          show-expand
          @click:row="edit"
          v-model="selected"
          show-select
        >
          <template v-slot:item.total_products="{ item }">
            {{ Math.trunc(item.total_products) }}
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ formatDate(item.created_at) }}
          </template>
          <template v-slot:item.sales_order_number="{ item }">
            <router-link
              :to="{
                name: 'SalesOrderIndex',
                params: { salesOrderId: item.sales_order_id },
              }"
              >{{ item.sales_order_number }}</router-link
            >
          </template>
          <template v-slot:item.status_title="{ item }">
            <v-chip :color="item.status_color" dark>
              {{ item.status_title }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <div style="display: inline-block; width: 150px">
              <Actions :item="item" @index="index"></Actions>
            </div>
            <v-btn
              icon
              title="Verwijderen"
              @click.stop="destroy(item.id)"
              class="mr-1"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
            <v-btn icon title="Wijzigen" @click.stop="edit(item)">
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-2">
              <Products :products="item.products"></Products>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
  </transition>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import ShipmentService from "@/services/ShipmentService";
import ShipmentEdit from "@/views/shipment/Edit.vue";
import Products from "@/views/shipment/components/Products.vue";
import Actions from "@/views/shipment/components/Actions.vue";
import ItemsActions from "@/views/shipment/components/ItemsActions.vue";

export default {
  props: {
    salesOrder: {
      type: Object,
      required: false,
      default: null,
    },
  },
  components: { ItemsActions, Actions, Products, ShipmentEdit },
  data() {
    return {
      loading: true,
      selected: [],
      search: "",
      options: {},
      expanded: [],
      singleExpand: true,
      openShipmentEdit: false,
      shipmentId: 0,
      allHeaders: [
        {
          text: "Verzendnr",
          align: "left",
          sortable: true,
          value: "increment_id",
        },
        {
          text: "Ordernr",
          align: "left",
          sortable: true,
          value: "sales_order_number",
        },
        {
          text: "Datum",
          align: "left",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Methode",
          align: "left",
          sortable: true,
          value: "method_title",
        },
        {
          text: "Barcode",
          align: "left",
          sortable: true,
          value: "barcode",
        },
        {
          text: "Producten",
          align: "left",
          sortable: true,
          value: "total_products",
        },
        {
          text: "Status",
          align: "left",
          sortable: true,
          value: "status_title",
        },
        {
          text: "Acties",
          align: "left",
          sortable: false,
          value: "actions",
        },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  computed: {
    ...mapState(["shipment"]),
    headers() {
      return this.allHeaders.filter((header) => {
        return this.salesOrder ? header.value !== "sales_order_number" : true;
      });
    },
    salesOrderId() {
      return this.salesOrder ? this.salesOrder.id : null;
    },
    canAdd() {
      if (!this.salesOrder) return false;
      return (
        this.salesOrder.quantity_ordered - this.salesOrder.quantity_shipped > 0
      );
    },
  },
  watch: {
    options: {
      deep: true,
      handler(newValue, oldValue) {
        if (!oldValue) {
          this.options = this.shipment.options;
          return;
        }
        this.$store.dispatch("shipment/setOptions", this.options);
        this.index();
      },
    },
    search: function (newValue, oldValue) {
      if (!oldValue) return;
      this.doSearch();
    },
    salesOrderId: function (newValue, oldValue) {
      console.log("watch");
      this.openShipmentEdit = false;
      if (!oldValue) return;
      this.doSearch();
    },
  },
  methods: {
    doSearch: _.debounce(function () {
      this.options.page = 1;
      this.$store.dispatch("shipment/setSearch", this.search);
      this.index();
    }, 500),
    index() {
      console.log("index()");
      this.loading = true;
      this.$store.dispatch("shipment/index", this.salesOrderId).then(() => {
        this.loading = false;
      });
      if (this.salesOrderId) {
        this.$store.dispatch("salesOrder/show", this.salesOrderId);
      }
    },
    closeShipmentEdit() {
      this.shipmentId = 0;
      this.openShipmentEdit = false;
      this.index();
    },
    add() {
      this.$store
        .dispatch("shipment/edit", { id: 0, sales_order_id: this.salesOrderId })
        .then(() => {
          this.openShipmentEdit = true;
        });
    },
    edit(item) {
      this.$store
        .dispatch("shipment/edit", { id: item.id, sales_order_id: 0 })
        .then(() => {
          this.openShipmentEdit = true;
        });
    },
    destroy(id) {
      this.$root.$confirm
        .open("Zending verwijderen", "Weet je het zeker?", { color: "accent" })
        .then((confirm) => {
          if (!confirm) return;
          ShipmentService.destroy(id)
            .then(({ data }) => {
              this.notify(data.message);
              this.index();
            })
            .catch((err) => {
              this.notifyErr(err);
            });
        });
    },
  },
};
</script>
