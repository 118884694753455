<template>
  <transition name="module-fade">
    <div class="module" v-if="loaded">
      <div class="m-primary">
        <div class="m-p-header">
          <v-toolbar flat dark color="primary">
            <v-btn icon :to="{ name: 'JobIndex' }">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title class=""
              ><v-icon left>mdi-account-job-outline</v-icon>{{ job.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              v-if="id && false"
              class="ma-2"
              @click.stop="destroy(id)"
              outlined
              rounded
              color="white"
            >
              <v-icon left>mdi-delete-outline</v-icon>
              Verwijder
            </v-btn>
            <v-btn
              class="ma-2 primary--text"
              rounded
              color="white"
              :loading="form.job.submitting"
              v-on:click="submit"
              ><v-icon dark left>mdi-content-save</v-icon> Opslaan</v-btn
            >
          </v-toolbar>
        </div>
        <div class="m-p-body">
          <div class="m-p-content pa-5">
            <v-container class="job-edit">
              <v-row>
                <v-col class="md-6">
                  <v-text-field
                    v-model="job.title"
                    label="Titel"
                    required
                    :error="form.job.fields.title.error"
                    :error-messages="form.job.fields.title.error_messages"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="job.cron_expression"
                    label="Cron expressie"
                    required
                    :error="form.job.fields.cron_expression.error"
                    :error-messages="
                      form.job.fields.cron_expression.error_messages
                    "
                    outlined
                    persistent-hint
                    :hint="job.cron_expression_codes"
                    class="mb-4"
                  ></v-text-field>
                  <div>
                    <pre><code>*    *    *    *    *
|    |    |    |    |
|    |    |    |    +----- dag van de week (0 - 7) (Zondag = 0 of 7)
|    |    |    +---------- maand (1 - 12)
|    |    +--------------- dag van de maand (1 - 31)
|    +-------------------- uur (0 - 23)
+------------------------- minuut (0 - 59)
</code></pre>
                  </div>
                </v-col>
                <v-col class="md-6"> </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import JobService from "@/services/JobService";

export default {
  props: ["id"],
  data() {
    return {
      loaded: false,
      job: {
        title: null,
        cron_expression: null,
      },
      form: {
        job: {
          submitting: false,
          fields: {
            title: { error: false, error_messages: [] },
            cron_expression: { error: false, error_messages: [] },
          },
        },
      },
    };
  },
  methods: {
    submit() {
      this.form.job.submitting = true;
      let formFields = this.form.job.fields;
      this.resetValidationErrors(formFields);
      const apiCall = this.id
        ? JobService.update(this.id, this.job)
        : JobService.store(this.job);
      apiCall
        .then(({ data }) => {
          this.notify(data.message);
          this.$router.push({ name: "JobIndex" });
          this.form.job.submitting = false;
        })
        .catch((err) => {
          this.notifyErr(err);
          if (err.response.status === 422) {
            this.showValidationErrors(formFields, err.response.data.errors);
          }
          this.form.job.submitting = false;
        });
    },
    destroy(id) {
      this.$root.$confirm
        .open("Verwijderen", "Weet je het zeker?")
        .then((confirm) => {
          if (!confirm) return;
          JobService.destroy(id)
            .then(({ data }) => {
              this.notify(data.message);
              this.$router.push({ name: "JobIndex" });
            })
            .catch((err) => {
              this.notifyErr(err);
            });
        });
    },
  },
  mounted() {
    console.log("mounted()");
    console.log("id", this.id);
    if (this.id) {
      JobService.edit(this.id)
        .then(({ data }) => {
          console.log("data", data);
          this.job = data.job;
          this.loaded = true;
        })
        .catch((err) => {
          this.notifyErr(err);
        });
    } else {
      JobService.create()
        .then(({ data }) => {
          console.log("data", data);
          this.job = data.job;
          console.log("this.job", this.job);
          this.loaded = true;
        })
        .catch((err) => {
          this.notifyErr(err);
        });
    }
  },
};
</script>

<style>
.theme--light.v-application code {
  background-color: rgba(0, 0, 0, 0) !important;
}
</style>
