<template>
  <div class="not-found primary--text">
    <h1>404 pagina niet gevonden</h1>
    <router-link :to="{ name: 'Dashboard' }">Dashboard</router-link>
    <router-link :to="{ name: 'SalesOrderIndex' }">Orders</router-link>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.not-found {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
