<template>
  <v-avatar :style="getStyle()" :size="getSize()">
    <img v-if="avatar.url" :src="avatar.url" />
    <span v-else>{{ avatar.text }}</span>
  </v-avatar>
</template>

<script>
export default {
  props: {
    avatar: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
    };
  },
  methods: {
    getStyle() {
      let style = {
        cursor: "pointer",
        fontSize: "16px",
      };
      if (this.avatar.url) {
        style.borderStyle = "solid";
        style.borderWidth = "1px";
        style.borderColor = "#004379";
        // style.outline = "1px solid #004379";
      }
      style.backgroundColor = "#004379";
      style.color = "#ffffff";
      return style;
    },
    getSize() {
      if (this.avatar.url) {
        return 38;
      }
      return 40;
    },
  },
};
</script>
