<template>
  <v-container
    fluid
    fill-height
    class="login-container"
    :style="{
      background: `center/cover url(${require('@/assets/bg_login.jpg')})`,
    }"
  >
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4 class="">
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title class="ml-4"
              >Wijzig je wachtwoord - Eduforce-orderbeheer</v-toolbar-title
            >
          </v-toolbar>
          <v-card-text class="pa-8" v-if="status == 2">
            <v-form>
              <v-text-field
                class="mt-0"
                prepend-icon="mdi-account"
                type="text"
                v-model="user.email"
                label="E-mailadres"
                outlined
                dense
                readonly
              ></v-text-field>
              <v-text-field
                class="mt-0"
                prepend-icon="mdi-lock"
                type="password"
                v-model="form.user.data.password"
                label="Nieuw wachtwoord"
                :error="form.user.fields.password.error"
                :error-messages="form.user.fields.password.error_messages"
                outlined
                dense
              ></v-text-field>
              <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                  text
                  dark
                  class="mr-3"
                  outlined
                  rounded
                  color="primary"
                  :to="{ name: 'UserLogin' }"
                  >Inloggen</v-btn
                >
                <v-btn
                  dark
                  color="primary"
                  rounded
                  :loading="form.user.submitting"
                  v-on:click.stop="submit"
                  >Wachtwoord wijzigen</v-btn
                >
              </div>
            </v-form>
          </v-card-text>
          <v-card-text class="pa-8" v-if="status == 3">
            Link is verlopen, vraag
            <router-link :to="{ name: 'UserPasswordRequest' }"
              >hier</router-link
            >
            een nieuwe link aan.
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import UserService from "@/services/UserService";

export default {
  props: ["id"],
  data() {
    return {
      status: 1,
      user: {},
      form: {
        user: {
          submitting: false,
          data: {
            token: null,
            password: null,
          },
          fields: {
            password: { error: false, error_messages: [] },
          },
        },
      },
    };
  },
  methods: {
    submit() {
      this.form.user.submitting = true;
      let formFields = this.form.user.fields;
      this.resetValidationErrors(formFields);
      UserService.password(this.form.user.data)
        .then(({ data }) => {
          this.notify(data.message);
          this.$router.push({ name: "UserLogin" });
          this.form.user.submitting = false;
        })
        .catch((err) => {
          this.notifyErr(err);
          if (err.response.status === 422) {
            this.showValidationErrors(formFields, err.response.data.errors);
          }
          this.form.user.submitting = false;
        });
    },
  },
  mounted() {
    UserService.passwordToken({ token: this.$route.query.t })
      .then(({ data }) => {
        this.form.user.data.token = this.$route.query.t;
        this.user = data.user;
        this.status = 2;
      })
      .catch(() => {
        this.status = 3;
        console.log(this.status);
      });
  },
};
</script>

<style>
.login-container {
}
</style>
