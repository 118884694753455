<template>
  <VueApexCharts
    v-if="series"
    type="bar"
    height="350"
    :options="chartOptions"
    :series="series"
  ></VueApexCharts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import ChartService from "@/services/ChartService";

export default {
  components: { VueApexCharts },
  data() {
    return {
      series: [],
      xaxisCategories: [],
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (val) => {
            return Math.round(val);
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        xaxis: {
          categories: this.xaxisCategories,
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          labels: {
            show: true,
            formatter: function (val) {
              return "€ " + val;
            },
          },
        },
        title: {
          text: "Omzet",
          floating: true,
          offsetY: 0,
          align: "center",
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            color: "#444",
          },
        },
      };
    },
  },
  mounted() {
    ChartService.revenue().then(({ data }) => {
      this.series = data.series;
      this.xaxisCategories = data.categories;
    });
  },
};
</script>
