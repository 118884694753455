import SalesOrderService from "@/services/SalesOrderService";

export const namespaced = true;

export const state = {
  items: [],
  totalItems: 0,
  item: null,
  prevItemId: null,
  nextItemId: null,
  product: null,
  shipment: null,
  options: {},
  search: {},
  brandFilter: [],
  groupFilter: [],
  mSecondary: true,
};

export const mutations = {
  SET_ITEMS(state, data) {
    state.items = data;
  },
  SET_TOTAL_ITEMS(state, data) {
    state.totalItems = data;
  },
  SET_ITEM(state, data) {
    state.item = data;
  },
  SET_PREV_ITEM_ID(state, data) {
    state.prevItemId = data;
  },
  SET_NEXT_ITEM_ID(state, data) {
    state.nextItemId = data;
  },
  SET_OPTIONS(state, data) {
    state.options = data;
  },
  SET_SEARCH(state, data) {
    state.search = data;
  },
};

export const actions = {
  index({ commit, state }) {
    let data = {
      ...state.options,
      search: state.search,
    };
    return SalesOrderService.index(data)
      .then(({ data }) => {
        commit("SET_ITEMS", data.salesOrders);
        commit("SET_TOTAL_ITEMS", data.recordsTotal);
      })
      .catch((err) => {
        this.notifyErr(err);
      });
  },
  show({ commit, dispatch }, data) {
    return SalesOrderService.show(data)
      .then(({ data }) => {
        commit("SET_ITEM", data.salesOrder);
        commit("SET_PREV_ITEM_ID", data.prevSalesOrderId);
        commit("SET_NEXT_ITEM_ID", data.nextSalesOrderId);
        dispatch("salesOrder/index", this.state.options, { root: true });
      })
      .catch((err) => {
        dispatch(
          "notification/add",
          {
            type: "error",
            message: err,
          },
          {
            root: true,
          }
        );
      });
  },
  getProduct({ commit }, data) {
    return SalesOrderService.getProduct(data).then(({ data }) => {
      commit("SET_ITEM", data.salesOrder);
      commit("SET_PREV_ITEM_ID", data.prevSalesOrderId);
      commit("SET_NEXT_ITEM_ID", data.nextSalesOrderId);
    });
  },
  setOptions({ commit }, data) {
    commit("SET_OPTIONS", data);
  },
  setSearch({ commit, dispatch }, data) {
    let options = state.options;
    console.log("options", options);
    options.page = 1;
    commit("SET_OPTIONS", options);
    commit("SET_SEARCH", data);
    dispatch("index");
  },
  updateStatus({ dispatch }, data) {
    console.log(data);
    return SalesOrderService.updateStatus(data.salesOrderId, {
      status_id: data.statusId,
    })
      .then(() => {
        dispatch("salesOrder/show", data.salesOrderId, { root: true });
      })
      .catch((err) => {
        dispatch(
          "notification/add",
          {
            type: "error",
            message: err,
          },
          {
            root: true,
          }
        );
      });
  },
  updateShipmentStatus({ dispatch }, data) {
    return SalesOrderService.updateShipmentStatus(data.salesOrderId, {
      shipment_status_id: data.statusId,
    })
      .then(() => {
        dispatch("salesOrder/show", data.salesOrderId, { root: true });
      })
      .catch((err) => {
        dispatch(
          "notification/add",
          {
            type: "error",
            message: err,
          },
          {
            root: true,
          }
        );
      });
  },
  updateAllsolutionsStatus({ dispatch }, data) {
    return SalesOrderService.updateAllsolutionsStatus(data.salesOrderId, {
      allsolutions_status_id: data.statusId,
    })
      .then(() => {
        dispatch("salesOrder/show", data.salesOrderId, { root: true });
      })
      .catch((err) => {
        dispatch(
          "notification/add",
          {
            type: "error",
            message: err,
          },
          {
            root: true,
          }
        );
      });
  },
};
