export default {
  methods: {
    formatCurrency: function (amount) {
      if (!amount) return "";
      let formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(amount);
    },
    friendlyBoolean: function (b) {
      return b ? "ja" : "nee";
    },
    formatDate: function (d) {
      if (!d) return "";
      let date = new Date(d);
      return new Intl.DateTimeFormat("nl-NL", {
        dateStyle: "short",
      }).format(date);
    },
    formatDateLong: function (d) {
      if (!d) return "";
      let date = new Date(d);
      return new Intl.DateTimeFormat("nl-NL", {
        dateStyle: "full",
      }).format(date);
    },
    formatDateTime: function (d) {
      if (!d) return "";
      let date = new Date(d);
      return new Intl.DateTimeFormat("nl-NL", {
        dateStyle: "short",
        timeStyle: "short",
      }).format(date);
    },
    formatDateTimeLong: function (d) {
      if (!d) return "";
      let date = new Date(d);
      return new Intl.DateTimeFormat("nl-NL", {
        dateStyle: "full",
        timeStyle: "short",
      }).format(date);
    },
    b64toBlob: function (b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    downloadBlob: function (blob, contentType, name) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", "");
      link.download = name;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    notify: function (message, type = "success") {
      if (!message) return;
      let notification = {
        type: type,
        message: message,
      };
      this.$store.dispatch("notification/add", notification, {
        root: true,
      });
    },
    notifyErr: function (err) {
      console.log(err);
      if (typeof err === "object") {
        if (Object.prototype.hasOwnProperty.call(err, "response")) {
          this.notify(err.response.data.message, "error");
        } else {
          this.notify(err, "error");
        }
      }
    },
    showValidationErrors: function (formFields, errorFields) {
      Object.keys(errorFields).map((errorField) => {
        formFields[errorField].error = true;
        formFields[errorField].error_messages = errorFields[errorField];
      });
    },
    resetValidationErrors: function (formFields) {
      Object.keys(formFields).map((formField) => {
        formFields[formField].error = false;
        formFields[formField].error_messages = null;
      });
    },
    confirm: function (message) {
      return new Promise((resolve) => {
        this.$confirm(message, {
          buttonTrueText: "Ja",
          buttonFalseText: "Nee",
          buttonTrueColor: "#004379",
        }).then((res) => {
          resolve(res);
        });
      });
    },
  },
};
