<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Sku</th>
          <th class="text-left">Product</th>
          <th class="text-left">Verzendgewicht</th>
          <th class="text-left">Vasthouden tot</th>
          <th class="text-left">Besteld</th>
          <th class="text-left">Verzonden</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="product in products"
          :key="`shipment${product.shipment_id}product${product.id}`"
        >
          <td>{{ product.sales_order_product.sku }}</td>
          <td>{{ product.sales_order_product.name }}</td>
          <td>
            <v-chip small outlined>
              {{ product.sales_order_product.product.shipment_weight }}
            </v-chip>
          </td>
          <td>
            <v-chip
              small
              outlined
              color="orange"
              v-if="product.sales_order_product.product.available_from"
            >
              {{
                formatDate(product.sales_order_product.product.available_from)
              }}
            </v-chip>
          </td>
          <td>{{ Math.trunc(product.quantity) }}</td>
          <td>
            {{ Math.trunc(product.sales_order_product.qty_ordered) }}
          </td>
          <!--                      <td>-->
          <!--                        {{ formatCurrency(product.sales_order_product.price) }}-->
          <!--                      </td>-->
          <!--                      <td>-->
          <!--                        {{-->
          <!--                          formatCurrency(-->
          <!--                            product.quantity * product.sales_order_product.price-->
          <!--                          )-->
          <!--                        }}-->
          <!--                      </td>-->
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: ["products"],
};
</script>
