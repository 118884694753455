<template>
  <div>
    <v-text-field
      style="width: 820px"
      v-model="search.state"
      prepend-inner-icon="mdi-magnify"
      label="Zoeken in orders"
      single-line
      hide-details
      outlined
      dense
      readonly
      rounded
      @click.stop="menu = !menu"
    >
      <template v-slot:append>
        <v-icon
          v-if="search.state"
          class="mr-3"
          @click.stop="reset"
          title="Zoekopdracht verwijderen"
          >mdi-close</v-icon
        >
        <v-menu
          v-model="menu"
          offset-y
          bottom
          left
          :close-on-content-click="false"
          :nudge-right="24"
          :nudge-bottom="9"
          transition="fade"
          min-width="820px"
          max-width="820px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon title="Zoekopties tonen" v-bind="attrs" v-on="on"
              >mdi-tune</v-icon
            >
          </template>
          <v-card class="pa-4" style="min-height: 300px">
            <v-form @keyup.native.enter="submit">
              <v-container class="mt-4">
                <v-row justify="space-between">
                  <v-col sm="4" class="py-0"><label>Zoekterm:</label> </v-col>
                  <v-col sm="8" class="py-0">
                    <v-text-field
                      v-model="search.text"
                      :error="form.search.fields.text.error"
                      :error-messages="form.search.fields.text.error_messages"
                      outlined
                      dense
                      ref="searchTextInput"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="space-between">
                  <v-col sm="4" class="py-0"
                    ><label>Orderstatus:</label>
                  </v-col>
                  <v-col sm="8" class="py-0">
                    <v-select
                      v-model="search.status_id"
                      item-text="title"
                      item-value="id"
                      :items="salesOrderStatus.items"
                      :error="form.search.fields.status_id.error"
                      :error-messages="
                        form.search.fields.status_id.error_messages
                      "
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row justify="space-between">
                  <v-col sm="4" class="py-0"
                    ><label>Verzendstatus:</label>
                  </v-col>
                  <v-col sm="8" class="py-0">
                    <v-select
                      v-model="search.shipment_status_id"
                      item-text="title"
                      item-value="id"
                      :items="salesOrderShipmentStatus.items"
                      :error="form.search.fields.shipment_status_id.error"
                      :error-messages="
                        form.search.fields.shipment_status_id.error_messages
                      "
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row justify="space-between">
                  <v-col sm="4" class="py-0"
                    ><label>Allsolutions exportstatus:</label>
                  </v-col>
                  <v-col sm="8" class="py-0">
                    <v-select
                      v-model="search.allsolutions_status_id"
                      item-text="title"
                      item-value="id"
                      :items="salesOrderAllsolutionsStatus.items"
                      :error="form.search.fields.allsolutions_status_id.error"
                      :error-messages="
                        form.search.fields.allsolutions_status_id.error_messages
                      "
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" align="right" class="py-0">
                    <v-btn
                      v-if="false"
                      dark
                      class="mr-3"
                      outlined
                      rounded
                      color="primary"
                      @click.stop="reset"
                      >Reset zoeken</v-btn
                    >
                    <v-btn
                      dark
                      color="primary"
                      rounded
                      :loading="form.search.submitting"
                      @click.stop="submit"
                      >Zoeken</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      menu: false,
      loaded: false,
      search: {
        state: "",
        text: "",
        status: null,
        shipment_status_id: null,
        allsolutions_status_id: null,
      },
      form: {
        search: {
          submitting: false,
          fields: {
            text: { error: false, error_messages: [] },
            status_id: { error: false, error_messages: [] },
            shipment_status_id: {
              error: false,
              error_messages: [],
            },
            allsolutions_status_id: {
              error: false,
              error_messages: [],
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapState([
      "salesOrderStatus",
      "salesOrderShipmentStatus",
      "salesOrderAllsolutionsStatus",
    ]),
    ...mapGetters({
      salesOrderStatusTitle: "salesOrderStatus/title",
      salesOrderShipmentStatusTitle: "salesOrderShipmentStatus/title",
      salesOrderAllsolutionsStatusTitle: "salesOrderAllsolutionsStatus/title",
    }),
  },
  methods: {
    submit() {
      //this.form.search.submitting = true;
      let state = "";
      let search = _.cloneDeep(this.search);
      if (search.status_id) {
        state =
          "orderstatus:(" + this.salesOrderStatusTitle(search.status_id) + ") ";
      }
      if (search.shipment_status_id) {
        state +=
          "verzendstatus:(" +
          this.salesOrderShipmentStatusTitle(search.shipment_status_id) +
          ") ";
      }
      if (search.allsolutions_status_id) {
        state +=
          "allsolutions-exportstatus:(" +
          this.salesOrderAllsolutionsStatusTitle(
            search.allsolutions_status_id
          ) +
          ") ";
      }
      if (search.text) {
        state += search.text;
      }
      this.search.state = state;
      this.$store.dispatch("salesOrder/setSearch", _.cloneDeep(this.search));
      this.menu = false;
      //this.$refs.menu.save();
    },
    reset() {
      this.search.state = "";
      this.search.text = "";
      this.search.status_id = null;
      this.search.shipment_status_id = null;
      this.search.allsolutions_status_id = null;
      this.$store.dispatch("salesOrder/setSearch", _.cloneDeep(this.search));
      this.menu = false;
      //this.$refs.menu.save();
    },
  },
  watch: {
    search: {
      handler(newValue, oldValue) {
        console.log("watch search", newValue, oldValue);
        //this.$store.dispatch("salesOrder/setSearch", this.search);
      },
      deep: true,
    },
    menu: {
      handler(newValue, oldValue) {
        console.log("watch menu", newValue, oldValue);
        // if (newValue) {
        setTimeout(() => {
          this.$refs.searchTextInput.focus();
        }, 100);
        // }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
label {
  line-height: 40px;
}
</style>
