<template>
  <div>
    <div class="text-h2 chart--text">{{ total }}</div>
    <div class="text-h6">Te verzenden producten</div>
  </div>
</template>

<script>
import ChartService from "@/services/ChartService";

export default {
  data() {
    return {
      total: 0,
    };
  },
  mounted() {
    ChartService.totalProductsNotShipped().then(({ data }) => {
      this.total = data.total;
    });
  },
};
</script>

<style>
.title {
  font-size: 25px;
  font-weight: bold;
}
</style>
