import UserService from "@/services/UserService";

const state = {
  isAuth: false,
  id: null,
  user_role_id: null,
  user_role_title: null,
  name: null,
  email: null,
  avatar: {},
  options: null,
  search: null,
};

const mutations = {
  SET(state, user) {
    state.isAuth = user?.email ? true : false;
    state.id = user.id;
    state.user_role_id = user.user_role_id;
    state.user_role_title = user.user_role_title;
    state.name = user.name;
    state.email = user.email;
    state.avatar = user.avatar;
  },
  SET_OPTIONS(state, data) {
    state.options = data;
  },
  SET_SEARCH(state, data) {
    state.search = data;
  },
};

const actions = {
  set({ commit }, user) {
    commit("SET", user);
  },
  auth({ commit }) {
    return UserService.auth().then((response) => {
      if (response.data.user) {
        commit("SET", response.data.user);
      }
    });
  },
  setOptions({ commit }, data) {
    commit("SET_OPTIONS", data);
  },
  setSearch({ commit }, data) {
    commit("SET_SEARCH", data);
  },
};

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
};
