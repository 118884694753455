<template>
  <v-menu
    v-model="menu"
    offset-y
    bottom
    right
    :close-on-content-click="false"
    :nudge-right="-28"
    :nudge-bottom="0"
    transition="fade"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-2"
        :color="shipment.status_color"
        dark
        v-bind="attrs"
        v-on="on"
        depressed
        rounded
      >
        <v-icon left>mdi-truck-cargo-container</v-icon>
        {{ shipment.status_title }}
      </v-btn>
    </template>
    <v-card class="pt-4 pb-2 px-8">
      <v-btn
        style="display: block"
        v-for="item in shipmentStatus.items"
        :key="`shipmentStatus${item.id}`"
        rounded
        outlined
        :color="item.color"
        @click="update(item.id)"
        class="mb-2"
        depressed
        >{{ item.title }}</v-btn
      >
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["shipment"],
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapState(["shipmentStatus"]),
  },
  methods: {
    update(statusId) {
      this.$store
        .dispatch("shipment/updateStatus", {
          id: this.shipment.id,
          status_id: statusId,
        })
        .then(() => {
          this.menu = false;
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
