import axios from "axios";

const apiClient = axios.create({
  baseURL:
    process.env.VUE_APP_HOST_API + "/api/sales-order-allsolutions-status",
  withCredentials: true,
  headers: {
    Accept: "application/json",
  },
});

apiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.data.status == 401) {
      //
    }
    return Promise.reject(error);
  }
);

export default {
  index() {
    return apiClient.get(null);
  },
};
