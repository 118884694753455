import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#004379",
        accent: colors.red.darken1,
        chart: "#26a0fc",
      },
    },
  },
});
