<template>
  <div class="loading">
    <v-icon dark>mdi-dots-circle mdi-spin</v-icon>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.loading {
  width: 100vw;
  height: 100vh;
  background-color: #333333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
