<template>
  <div class="m-p-content" v-if="salesOrder">
    <BillingAddressEdit
      v-if="openBillingAddressEdit"
      :id="salesOrder.billing_address.id"
      :open="openBillingAddressEdit"
      :salesOrder="salesOrder"
      @close="close('openBillingAddressEdit')"
    />
    <ShippingAddressEdit
      v-if="openShippingAddressEdit"
      :id="salesOrder.shipping_address.id"
      :open="openShippingAddressEdit"
      :salesOrder="salesOrder"
      @close="close('openShippingAddressEdit')"
    />
    <v-app-bar class="ml-1 mr-1" flat color="#ffffff">
      <v-toolbar-title>Info</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-container>
      <v-row>
        <v-col>
          <v-card outlined class="pa-4">
            <v-container>
              <v-row>
                <v-col> Order Datum </v-col>
                <v-col>
                  {{ formatDate(salesOrder.created_at) }}
                </v-col>
              </v-row>
              <v-row>
                <v-col> Betaal methode </v-col>
                <v-col>
                  {{ salesOrder.data.payment.method }}
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined class="pa-4">
            <v-container>
              <v-row>
                <v-col>Klant </v-col>
                <v-col>
                  {{ salesOrder.customer_name }}
                </v-col>
              </v-row>
              <v-row>
                <v-col> E-mail </v-col>
                <v-col>
                  {{ salesOrder.billing_address.email }}
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined class="pa-4" style="position: relative">
            <v-container>
              <v-btn class="ce-tr" icon @click="openBillingAddressEdit = true">
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
              <v-row>
                <v-col>
                  <div>Factuur adres</div>
                </v-col>
                <v-col v-if="salesOrder.billing_address">
                  {{ salesOrder.billing_address.company }}<br />
                  {{ salesOrder.billing_address.full_name }}<br />
                  {{ salesOrder.billing_address.full_street }}<br />
                  {{ salesOrder.billing_address.postcode }}
                  {{ salesOrder.billing_address.city }}<br />
                  {{ salesOrder.billing_address.country }}<br />
                  {{ salesOrder.billing_address.telephone }}<br />
                  {{ salesOrder.billing_address.email }}<br />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined class="pa-4" style="position: relative">
            <v-container>
              <v-btn class="ce-tr" icon @click="openShippingAddressEdit = true">
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
              <v-row>
                <v-col>Verzend adres </v-col>
                <v-col v-if="salesOrder.shipping_address">
                  {{ salesOrder.shipping_address.company }}<br />
                  {{ salesOrder.shipping_address.full_name }}<br />
                  {{ salesOrder.shipping_address.full_street }}<br />
                  {{ salesOrder.shipping_address.postcode }}
                  {{ salesOrder.shipping_address.city }}<br />
                  {{ salesOrder.shipping_address.country }}<br />
                  {{ salesOrder.shipping_address.telephone }}<br />
                  {{ salesOrder.shipping_address.email }}<br />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined class="pa-4 mb-8">
            <v-container>
              <v-row>
                <v-col>
                  <Comments :salesOrderId="salesOrderId"></Comments>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col> </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BillingAddressEdit from "@/views/sales_order_address/Edit.vue";
import ShippingAddressEdit from "@/views/sales_order_address/Edit.vue";
import Comments from "@/views/sales_order/components/Comments.vue";

export default {
  components: { Comments, BillingAddressEdit, ShippingAddressEdit },
  props: ["salesOrder"],
  data() {
    return {
      openBillingAddressEdit: false,
      openShippingAddressEdit: false,
    };
  },
  computed: {
    salesOrderId() {
      return this.salesOrder.id;
    },
  },
  methods: {
    close(dialog) {
      this[dialog] = false;
    },
  },
};
</script>
