<template>
  <transition name="module-fade">
    <div class="module" v-if="product.items">
      <div class="m-p-header">
        <v-app-bar flat dark color="primary">
          <v-toolbar-title v-if="false">Producten</v-toolbar-title>
          <div>
            <v-text-field
              style="width: 400px"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              clear-icon="mdi-close-circle-outline"
              clearable
              label="Zoeken in producten"
              single-line
              hide-details
              outlined
              dense
              rounded
            ></v-text-field>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            outlined
            @click.stop="synchronise()"
            :loading="synchroniseLoading"
            ><v-icon dark left>mdi-sync</v-icon>Synchroniseren</v-btn
          >
        </v-app-bar>
      </div>
      <div class="m-p-content">
        <v-data-table
          :hide-default-footer="product.items.length < 4"
          :headers="headers"
          :items="product.items"
          :options.sync="options"
          :server-items-length="product.totalItems"
          :loading="loading"
          @click:row="details"
          item-key="id"
        >
          <template v-slot:item.shipment_weight="{ item }">
            <v-chip small outlined>
              {{ item.shipment_weight }}
            </v-chip>
          </template>
          <template v-slot:item.import_status_title="{ item }">
            <v-chip :color="item.import_status_color" dark>
              {{ item.import_status_title }}
            </v-chip>
          </template>
          <template v-slot:item.available_from="{ item }">
            {{ formatDate(item.available_from) }}
          </template>
          <template v-slot:item.import_checked_at="{ item }">
            {{ formatDateTime(item.import_checked_at) }}
          </template>
          <template v-slot:item.import_updated_at="{ item }">
            {{ formatDateTime(item.import_updated_at) }}
          </template>
          <template v-slot:item.export_status_title="{ item }">
            <v-chip :color="item.export_status_color" dark>
              {{ item.export_status_title }}
            </v-chip>
          </template>
          <template v-slot:item.export_updated_at="{ item }">
            {{ formatDateTime(item.export_updated_at) }}
          </template>
          <template v-slot:item.sales_price="{ item }">
            {{ formatCurrency(item.sales_price) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              v-if="false"
              icon
              title="Verwijderen"
              @click.stop="destroy(item.id)"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
            <v-btn icon title="Wijzigen" @click.stop="details(item)">
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>
  </transition>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import ProductService from "@/services/ProductService";
import JobService from "@/services/JobService";

export default {
  data() {
    return {
      loading: true,
      search: "",
      options: null,
      synchroniseLoading: false,
      headers: [
        {
          text: "Artikelnr",
          align: "left",
          sortable: true,
          value: "article_nr",
        },
        {
          text: "Naam",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Locatie",
          align: "left",
          sortable: true,
          value: "location",
        },
        {
          text: "Verzendgewicht",
          align: "left",
          sortable: true,
          value: "shipment_weight",
        },
        {
          text: "Vasthouden tot",
          align: "left",
          sortable: true,
          value: "available_from",
        },
        {
          text: "AllSolutions status",
          align: "left",
          sortable: true,
          value: "import_status_title",
        },
        {
          text: "Magento status",
          align: "left",
          sortable: true,
          value: "export_status_title",
        },
        {
          text: "Acties",
          align: "left",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapState(["product"]),
  },
  watch: {
    options: {
      deep: true,
      handler(newValue, oldValue) {
        if (!oldValue) {
          this.options = this.product.options;
          return;
        }
        this.$store.dispatch("product/setOptions", this.options);
        this.index();
      },
    },
    search: function (newValue, oldValue) {
      if (!oldValue) return;
      this.doSearch();
    },
  },
  methods: {
    details(item) {
      this.$router.push({
        name: "ProductDetails",
        params: { id: item.id },
      });
    },
    destroy(id) {
      this.$root.$confirm
        .open("Verwijderen", "Weet je het zeker?")
        .then((confirm) => {
          if (!confirm) return;
          ProductService.destroy(id)
            .then(({ data }) => {
              this.notify(data.message);
              this.index();
            })
            .catch((err) => {
              this.notifyErr(err);
            });
        });
    },
    doSearch: _.debounce(function () {
      this.options.page = 1;
      this.$store.dispatch("product/setSearch", this.search);
      this.index();
    }, 500),
    index() {
      this.loading = true;
      this.$store.dispatch("product/index", this.options).then(() => {
        this.loading = false;
      });
    },
    synchronise() {
      this.synchroniseLoading = true;
      JobService.execute(1)
        .then(({ data }) => {
          this.notify(data.message);
          this.index();
          JobService.execute(2)
            .then(({ data }) => {
              this.notify(data.message);
              this.index();
              this.synchroniseLoading = false;
            })
            .catch((err) => {
              this.notifyErr(err);
              this.synchroniseLoading = false;
            });
        })
        .catch((err) => {
          this.notifyErr(err);
          this.synchroniseLoading = false;
        });
    },
  },
};
</script>
