<template>
  <v-dialog v-model="open" hide-overlay attach=".v-main__wrap">
    <v-card v-if="loaded">
      <v-toolbar flat dark color="primary">
        <v-btn icon @click="close">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>Orderadres wijzigen</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-2 primary--text"
          rounded
          color="white"
          :loading="form.submitting"
          v-on:click="submit"
          ><v-icon dark left>mdi-content-save</v-icon> Opslaan</v-btn
        >
      </v-toolbar>
      <v-container class="salesOrder-edit pa-5">
        <v-row>
          <v-col class="col-6">
            <v-text-field
              v-model="form.data.company"
              label="Bedrijf"
              required
              :error="form.fields.company.error"
              :error-messages="form.fields.company.error_messages"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.data.firstname"
              label="Voorletters"
              required
              :error="form.fields.firstname.error"
              :error-messages="form.fields.firstname.error_messages"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.data.middlename"
              label="Tussenvoegsel"
              required
              :error="form.fields.middlename.error"
              :error-messages="form.fields.middlename.error_messages"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.data.lastname"
              label="Achternaam"
              required
              :error="form.fields.lastname.error"
              :error-messages="form.fields.lastname.error_messages"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.data.email"
              label="E-mail"
              required
              :error="form.fields.email.error"
              :error-messages="form.fields.email.error_messages"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.data.telephone"
              label="Telefoon"
              required
              :error="form.fields.telephone.error"
              :error-messages="form.fields.telephone.error_messages"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col class="col-6">
            <v-text-field
              v-model="form.data.street"
              label="Straat"
              required
              :error="form.fields.street.error"
              :error-messages="form.fields.street.error_messages"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.data.number"
              label="Nummer"
              required
              :error="form.fields.number.error"
              :error-messages="form.fields.number.error_messages"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.data.appendix"
              label="Toevoeging"
              required
              :error="form.fields.appendix.error"
              :error-messages="form.fields.appendix.error_messages"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.data.postcode"
              label="Postcode"
              required
              :error="form.fields.postcode.error"
              :error-messages="form.fields.postcode.error_messages"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.data.city"
              label="Plaats"
              required
              :error="form.fields.city.error"
              :error-messages="form.fields.city.error_messages"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.data.country"
              label="Land"
              required
              :error="form.fields.country.error"
              :error-messages="form.fields.country.error_messages"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import SalesOrderAddressService from "@/services/SalesOrderAddressService";

export default {
  props: ["open", "id", "salesOrder"],
  data() {
    return {
      loaded: false,
      form: {
        submitting: false,
        fields: {
          company: { error: false, error_messages: [] },
          firstname: { error: false, error_messages: [] },
          middlename: { error: false, error_messages: [] },
          lastname: { error: false, error_messages: [] },
          street: { error: false, error_messages: [] },
          number: { error: false, error_messages: [] },
          appendix: { error: false, error_messages: [] },
          postcode: { error: false, error_messages: [] },
          city: { error: false, error_messages: [] },
          country: { error: false, error_messages: [] },
          telephone: { error: false, error_messages: [] },
          email: { error: false, error_messages: [] },
        },
        data: {
          company: null,
          firstname: null,
          middlename: null,
          lastname: null,
          street: null,
          number: null,
          appendix: null,
          postcode: null,
          city: null,
          country: null,
          telephone: null,
          email: null,
        },
      },
    };
  },
  computed: {},
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      this.form.submitting = true;
      let formFields = this.form.fields;
      this.resetValidationErrors(formFields);
      const apiCall = SalesOrderAddressService.update(this.id, this.form.data);
      apiCall
        .then(({ data }) => {
          this.$store.dispatch("salesOrder/show", this.salesOrder.id);
          this.notify(data.message);
          this.close();
          this.form.submitting = false;
        })
        .catch((err) => {
          this.notifyErr(err);
          if (err.response.status === 422) {
            this.showValidationErrors(formFields, err.response.data.errors);
          }
          this.form.submitting = false;
        });
    },
  },
  mounted() {
    console.log("mounted()");
    SalesOrderAddressService.edit(this.id)
      .then(({ data }) => {
        this.form.data = data.salesOrderAddress;
        this.loaded = true;
      })
      .catch((err) => {
        this.notifyErr(err);
      });
  },
};
</script>
