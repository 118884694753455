<template>
  <v-container
    fluid
    fill-height
    :style="{
      background: `center/cover url(${require('@/assets/bg_login.jpg')})`,
    }"
  >
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4 class="">
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title class="ml-4"
              >Wachtwoord wijzigen - Eduforce-orderbeheer</v-toolbar-title
            >
          </v-toolbar>
          <v-card-subtitle class="pr-8 pb-8 pl-8" v-if="status == 1"
            >Vul je e-mailadres in, dan ontvang je een e-mailbericht met daarin
            een link om je wachtwoord te wijzigen.</v-card-subtitle
          >
          <v-card-subtitle class="pr-8 pb-8 pl-8" v-if="status == 2">
            <Alert
              :alert="{
                type: 'success',
                message:
                  'Als je e-mailadres voorkomt in Eduforce-orderbeheer ontvang je een e-mailbericht met daarin een link om je wachtwoord te wijzigen.',
              }"
            ></Alert>
          </v-card-subtitle>
          <v-card-text class="pr-8 pb-8 pl-8">
            <v-form>
              <v-text-field
                class="mt-0"
                prepend-icon="mdi-account"
                type="text"
                v-model="user.email"
                label="E-mailadres"
                :error="form.user.fields.email.error"
                :error-messages="form.user.fields.email.error_messages"
                :disabled="form.user.fields.email.disabled"
                outlined
                dense
              ></v-text-field>
              <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                  text
                  dark
                  class="mr-3"
                  outlined
                  rounded
                  color="primary"
                  :to="{ name: 'UserLogin' }"
                  >Inloggen</v-btn
                >
                <v-btn
                  v-if="status == 1"
                  dark
                  color="primary"
                  rounded
                  :loading="form.user.submitting"
                  v-on:click.stop="submit"
                  >Wachtwoord wijzigen</v-btn
                >
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import UserService from "@/services/UserService";
import Alert from "../../components/Alert.vue";

export default {
  components: { Alert },
  props: ["id"],
  data() {
    return {
      status: 1,
      user: {
        email: null,
      },
      form: {
        user: {
          submitting: false,
          fields: {
            email: { error: false, error_messages: [], disabled: false },
          },
        },
      },
    };
  },
  methods: {
    submit() {
      this.form.user.submitting = true;
      let formFields = this.form.user.fields;
      this.resetValidationErrors(formFields);
      UserService.passwordRequest(this.user)
        .then(() => {
          this.form.user.submitting = false;
          this.form.user.fields.email.disabled = true;
          this.status = 2;
        })
        .catch((err) => {
          this.notifyErr(err);
          if (err.response.status === 422) {
            this.showValidationErrors(formFields, err.response.data.errors);
          }
          this.form.user.submitting = false;
        });
    },
  },
};
</script>
