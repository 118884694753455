<template>
  <div>
    <v-btn
      icon
      title="Pakbon PDF"
      @click.stop="picklistsPdf()"
      class="mr-1"
      :loading="picklistSubmitting"
    >
      <v-icon>mdi-order-bool-ascending-variant</v-icon>
    </v-btn>
    <v-btn
      icon
      title="Verzendlabel PDF"
      @click.stop="labelsPdf()"
      class="mr-1"
      :loading="labelSubmitting"
    >
      <v-icon>mdi-truck-cargo-container</v-icon>
    </v-btn>
    <v-btn
      icon
      title="E-mail verzendbevestiging"
      @click.stop="confirmations()"
      class="mr-1"
      :loading="confirmationSubmitting"
    >
      <v-icon>mdi-email-outline</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ShipmentService from "@/services/ShipmentService";

export default {
  props: ["items"],
  data() {
    return {
      confirmationSubmitting: false,
      picklistSubmitting: false,
      labelSubmitting: false,
    };
  },
  computed: {
    ...mapState(["shipmentStatus"]),
    data() {
      return { ids: this.items.map((item) => item.id) };
    },
  },
  methods: {
    picklistsPdf() {
      this.picklistSubmitting = true;
      ShipmentService.picklistsPdf(this.data)
        .then((response) => {
          this.downloadBlob(response.data, "application/pdf", "pakbonnen.pdf");
          this.$emit("index");
          this.picklistSubmitting = false;
        })
        .catch((err) => {
          this.notifyErr(err);
          this.picklistSubmitting = false;
        });
    },
    labelsPdf() {
      this.labelSubmitting = true;
      ShipmentService.labelsPdf(this.data)
        .then((response) => {
          this.downloadBlob(response.data, "application/pdf", "labels.pdf");
          this.$emit("index");
          this.labelSubmitting = false;
        })
        .catch((err) => {
          this.notifyErr(err);
          this.labelSubmitting = false;
        });
    },
    confirmations() {
      this.confirmationSubmitting = true;
      ShipmentService.confirmations(this.data)
        .then(({ data }) => {
          this.notify(data.message);
          this.$emit("index");
          setTimeout(() => {
            this.confirmationSubmitting = false;
          });
        })
        .catch((err) => {
          this.notifyErr(err);
          this.confirmationSubmitting = false;
        });
    },
  },
};
</script>
