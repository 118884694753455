<template>
  <div class="m-p-content" v-if="salesOrder.products">
    <v-toolbar class="ml-1 mr-1" flat color="#ffffff">
      <div>
        <v-toolbar-title>Producten</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-data-table
      :hide-default-footer="salesOrder.products.length < 50"
      :headers="headers"
      :items="salesOrder.products"
      :items-per-page="50"
      item-key="item_id"
    >
      <template v-slot:item.product.shipment_weight="{ item }">
        <v-chip small outlined>
          {{ item.product.shipment_weight }}
        </v-chip>
      </template>
      <template v-slot:item.product.available_from="{ item }">
        <v-chip
          small
          outlined
          color="orange"
          v-if="item.product.available_from"
        >
          {{ formatDate(item.product.available_from) }}
        </v-chip>
      </template>
      <template v-slot:item.qty_ordered="{ item }">
        {{ Math.trunc(item.qty_ordered) }}
      </template>
      <template v-slot:item.quantity_shipped="{ item }">
        {{ Math.trunc(item.quantity_shipped) }}
      </template>
      <template v-slot:item.price="{ item }">
        {{ formatCurrency(item.price) }}
      </template>
      <template v-slot:item.tax_amount="{ item }">
        {{ formatCurrency(item.tax_amount) }}
      </template>
      <template v-slot:item.tax_percent="{ item }">
        {{ formatCurrency(item.tax_percent) }}
      </template>
      <template v-slot:item.discount_amount="{ item }">
        {{ formatCurrency(item.discount_amount) }}
      </template>
      <template v-slot:item.row_total_incl_tax="{ item }">
        {{ formatCurrency(item.row_total_incl_tax) }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :color="getStatusColor(item.status)" dark>
          {{ item.status }}
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ["salesOrder"],
  data() {
    return {
      search: "",
      total: 0,
      loading: true,
      selectedProducts: [],
      headers: [
        {
          text: "Sku",
          align: "left",
          sortable: true,
          value: "sku",
        },
        {
          text: "Product",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Verzendgewicht",
          align: "left",
          sortable: true,
          value: "product.shipment_weight",
        },
        {
          text: "Vasthouden tot",
          align: "left",
          sortable: true,
          value: "product.available_from",
        },
        {
          text: "Besteld",
          align: "right",
          sortable: true,
          value: "qty_ordered",
        },
        {
          text: "Verzonden",
          align: "right",
          sortable: true,
          value: "quantity_shipped",
        },
        // {
        //   text: "Prijs",
        //   align: "right",
        //   sortable: false,
        //   value: "price",
        // },
        // {
        //   text: "BTW bedrag",
        //   align: "right",
        //   sortable: false,
        //   value: "tax_amount",
        // },
        // {
        //   text: "BTW percentage",
        //   align: "right",
        //   sortable: false,
        //   value: "tax_percent",
        // },
        // {
        //   text: "Korting",
        //   align: "right",
        //   sortable: false,
        //   value: "discount_amount",
        // },
        // {
        //   text: "Totaal",
        //   align: "right",
        //   sortable: false,
        //   value: "row_total_incl_tax",
        // },
      ],
    };
  },
  computed: {
    hasSelected() {
      return this.selectedProducts.length;
    },
  },
};
</script>
