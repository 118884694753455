<template>
  <div v-if="loaded">
    <router-view />
  </div>
  <v-app v-else>
    <v-main>
      <Loader />
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import SanctumService from "@/services/SanctumService";
import Loader from "@/components/Loader";

export default {
  components: { Loader },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState(["config", "network"]),
  },
  created() {
    SanctumService.csrfCookie().then(() => {
      const promiseConfigGet = this.$store.dispatch("config/get");
      const promiseUserAuth = this.$store.dispatch("user/auth");
      Promise.all([promiseConfigGet, promiseUserAuth]).then(() => {
        this.loaded = true;
      });
    });

    //socket.io
    // window.ws.connector.socket.on('connect', (data) => {
    //   console.log('ws connected', window.ws.socketId())
    //   this.$store.dispatch('network/setWebsockets', true)
    //   this.$store.dispatch('network/setSocketId', window.ws.socketId())
    // })
    // window.ws.connector.socket.on('disconnect',  (data) => {
    //   console.log('ws disconnected')
    //   this.$store.dispatch('network/setWebsockets', false)
    //   this.$store.dispatch('network/setSocketId', null)
    // })
    // window.ws.connector.socket.on('reconnecting',  (attemptNumber) => {
    //   console.log('ws reconnecting', attemptNumber)
    // })

    //pusher.com and laravel-websockets
    // this.ws.connector.pusher.connection.bind("connected", (data) => {
    //   console.log("ws connected", data.socket_id);
    //   this.$store.dispatch("network/setWebsockets", true);
    //   this.$store.dispatch("network/setSocketId", data.socket_id);
    // });
    // this.ws.connector.pusher.connection.bind("disconnected", () => {
    //   console.log("ws disconnected");
    //   this.$store.dispatch("network/setWebsockets", false);
    //   this.$store.dispatch("network/setSocketId", null);
    // });
    // this.ws.connector.pusher.connection.bind("state_change", function (states) {
    //   console.log("ws states.current", states.current);
    // });
  },
};
</script>

<style lang="scss">
html {
  overflow-y: hidden;
}

.module {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.module-fade-enter-active {
  transition: all 0.1s ease;
}
.module-fade-leave-active {
  transition: all 0.5s ease;
}
.module-fade-enter,
.module-fade-leave-to {
  opacity: 0;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  background-color: #ffffff;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #c1c1c1;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(193, 193, 193);
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.m-p-content {
  height: calc(100vh - 128px);
  overflow-y: auto;
}

.v-data-footer__select {
  visibility: hidden;
}

.v-main__wrap > .v-dialog__content {
  display: block !important;
}

.v-main__wrap > .v-dialog__content .v-dialog {
  margin: 64px 0 0 !important;
  /*overflow-y: hidden;*/
  transition: none !important;
  width: calc(100vw - 287px) !important;
  height: calc(100% - 64px - 15px) !important;
  max-height: calc(100% - 64px - 15px) !important;
  min-height: calc(100% - 64px - 15px) !important;
  position: absolute !important;
  right: 15px !important;
  border-radius: 16px !important;
  box-shadow: none !important;
}

.v-main__wrap > .v-dialog__content .v-dialog > div {
  height: 100%;
}

.ce-tr {
  position: absolute !important;
  top: 5px;
  right: 5px;
}

.v-tabs--vertical .v-tab {
  justify-content: left;
}

.v-tab {
  text-transform: none !important;
}
</style>
