import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_HOST_API + "/api/chart",
  withCredentials: true,
  headers: {
    Accept: "application/json",
  },
});

export default {
  revenue() {
    return apiClient.get("/revenue");
  },
  shipments() {
    return apiClient.get("/shipments");
  },
  salesOrderProducts() {
    return apiClient.get("/sales-order-products");
  },
  shipmentproducts() {
    return apiClient.get("/shipment-products");
  },
  totalSalesOrdersNotShipped() {
    return apiClient.get("/total-sales-orders-not-shipped");
  },
  totalProductsNotShipped() {
    return apiClient.get("/total-products-not-shipped");
  },
  totalSalesOrdersStatus(salesOrderStatusId) {
    return apiClient.get("/total-sales-orders-status/" + salesOrderStatusId);
  },
};
