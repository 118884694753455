<template>
  <transition name="module-fade">
    <div class="module" v-if="jobs">
      <div class="m-p-header">
        <v-app-bar flat dark color="primary">
          <v-toolbar-title v-if="!showSearch">Taken</v-toolbar-title>
          <div v-else>
            <v-text-field
              style="width: 400px"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              clear-icon="mdi-close-circle-outline"
              clearable
              label="Zoeken in jobs"
              single-line
              hide-details
              outlined
              dense
              rounded
            ></v-text-field>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            v-if="false"
            class="ma-2 primary--text"
            rounded
            color="white"
            :to="{ name: 'JobDetails', params: { id: 0 } }"
            ><v-icon dark left>mdi-plus</v-icon>Toevoegen</v-btn
          >
        </v-app-bar>
      </div>
      <div class="m-p-content">
        <v-data-table
          v-if="jobs.length"
          :hide-default-footer="jobs.length < 4"
          :headers="headers"
          :items="jobs"
          :options.sync="options"
          :server-items-length="jobsLength"
          :loading="loading"
          @click:row="details"
          item-key="id"
        >
          <template v-slot:item.status_title="{ item }">
            <v-chip :color="item.status_color" dark>
              {{ item.status_title }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item, index }">
            <v-btn
              icon
              title="Uitvoeren"
              @click.stop="execute(item.id, index)"
              class="mr-1"
              :loading="executeSubmitting[index]"
              ><v-icon>mdi-sync</v-icon>
            </v-btn>
            <v-btn icon title="Wijzigen" @click.stop="details(item)">
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>
  </transition>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import JobService from "@/services/JobService";

export default {
  data() {
    return {
      jobs: null,
      jobsLength: null,
      search: null,
      loading: true,
      options: {},
      executeSubmitting: [],
      headers: [
        {
          text: "Titel",
          align: "left",
          sortable: false,
          value: "title",
        },
        {
          text: "Status",
          align: "left",
          sortable: false,
          value: "status_title",
        },
        {
          text: "Volgende keer start op",
          align: "left",
          sortable: false,
          value: "next_run_date",
        },
        {
          text: "Laatste keer starte op",
          align: "left",
          sortable: false,
          value: "started_at",
        },
        {
          text: "Laatste keer stopte op",
          align: "left",
          sortable: false,
          value: "stopped_at",
        },
        {
          text: "Laatste keer duurde (sec)",
          align: "left",
          sortable: false,
          value: "duration",
        },
        {
          text: "Acties",
          align: "left",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapState(["job"]),
    showSearch() {
      return this.jobsLength > 10;
    },
  },
  watch: {
    options: {
      deep: true,
      handler() {
        if (this.options.itemsPerPage == -1) this.options.itemsPerPage = 1000;
        this.$store.dispatch("job/setOptions", this.options);
        this.index();
      },
    },
    search: function (newValue, oldValue) {
      if (!oldValue) return;
      this.doSearch();
    },
  },
  mounted() {
    this.options = _.cloneDeep(this.job.options);
    this.search = _.cloneDeep(this.job.search);
  },
  methods: {
    details(item) {
      this.$router.push({
        name: "JobDetails",
        params: { id: item.id },
      });
    },
    doSearch: _.debounce(function () {
      this.options.page = 1;
      this.$store.dispatch("job/setSearch", this.search);
      this.index();
    }, 500),
    index() {
      let data = _.cloneDeep(this.options);
      data.search = this.search;
      JobService.index(data).then(({ data }) => {
        this.jobs = data.data.jobs;
        this.jobsLength = data.data.jobsLength;
      });
      this.loading = false;
    },
    execute(id, index) {
      this.$set(this.executeSubmitting, index, true);
      setTimeout(() => {
        this.index();
      }, 50);
      JobService.execute(id)
        .then(({ data }) => {
          this.notify(data.message);
          this.index();
          setTimeout(() => {
            this.$set(this.executeSubmitting, index, false);
          });
        })
        .catch((err) => {
          this.notifyErr(err);
          this.$set(this.executeSubmitting, index, false);
        });
    },
  },
  created() {
    this.executeSubmitting = this.job.items.map(() => {
      return false;
    });
  },
};
</script>
