<template>
  <div>
    <v-btn
      icon
      title="Pakbon PDF"
      @click.stop="picklistPdf()"
      class="mr-1"
      :loading="picklistSubmitting"
    >
      <v-badge
        :color="item.picklist_printed ? 'green' : 'blue'"
        :content="item.picklist_printed.toString()"
        overlap
      >
        <v-icon>mdi-order-bool-ascending-variant</v-icon>
      </v-badge>
    </v-btn>
    <v-btn
      icon
      title="Verzendlabel PDF"
      @click.stop="labelPdf()"
      class="mr-1"
      :loading="labelSubmitting"
    >
      <v-badge
        :color="item.label_printed ? 'green' : 'blue'"
        :content="item.label_printed.toString()"
        overlap
      >
        <v-icon>mdi-truck-cargo-container</v-icon>
      </v-badge>
    </v-btn>
    <v-btn
      icon
      title="Verzend e-mail bevestiging"
      @click.stop="confirmation()"
      class="mr-1"
      :loading="confirmationSubmitting"
    >
      <v-badge
        :color="item.confirmation_mailed ? 'green' : 'blue'"
        :content="item.confirmation_mailed.toString()"
        overlap
      >
        <v-icon>mdi-email-outline</v-icon>
      </v-badge>
    </v-btn>
  </div>
</template>

<script>
import ShipmentService from "@/services/ShipmentService";

export default {
  props: ["item"],
  data() {
    return {
      confirmationSubmitting: false,
      picklistSubmitting: false,
      labelSubmitting: false,
    };
  },
  methods: {
    picklistPdf() {
      this.picklistSubmitting = true;
      ShipmentService.picklistPdf(this.item.id)
        .then((response) => {
          this.downloadBlob(
            response.data,
            "application/pdf",
            "Pakbon-" +
              this.item.increment_id +
              "-" +
              (this.item.picklist_printed + 1) +
              ".pdf"
          );
          this.$emit("index");
          this.picklistSubmitting = false;
        })
        .catch((err) => {
          this.notifyErr(err);
          this.picklistSubmitting = false;
        });
    },
    labelPdf() {
      this.labelSubmitting = true;
      ShipmentService.labelPdf(this.item.id)
        .then((response) => {
          const blob = this.b64toBlob(response.data, "application/pdf");
          this.downloadBlob(
            blob,
            "application/pdf",
            "Verzendlabel-" +
              this.item.increment_id +
              "-" +
              (this.item.label_printed + 1) +
              ".pdf"
          );
          this.$emit("index");
          this.labelSubmitting = false;
        })
        .catch((err) => {
          this.notifyErr(err);
          this.$emit("index");
          this.labelSubmitting = false;
        });
    },
    confirmation() {
      this.confirmationSubmitting = true;
      ShipmentService.confirmation(this.item.id)
        .then(({ data }) => {
          this.notify(data.message);
          this.$emit("index");
          setTimeout(() => {
            this.confirmationSubmitting = false;
          });
        })
        .catch((err) => {
          this.notifyErr(err);
          this.confirmationSubmitting = false;
        });
    },
  },
};
</script>
