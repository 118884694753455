<template>
  <transition name="module-fade">
    <div class="module" v-if="shipmentMethods">
      <div class="m-p-header">
        <v-app-bar flat dark color="primary">
          <v-toolbar-title v-if="false">Verzendmethoden</v-toolbar-title>
          <div>
            <v-text-field
              style="width: 400px"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              clear-icon="mdi-close-circle-outline"
              clearable
              label="Zoeken in verzendmethoden"
              single-line
              hide-details
              outlined
              dense
              rounded
            ></v-text-field>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2 primary--text"
            rounded
            color="white"
            :to="{ name: 'ShipmentMethodDetails', params: { id: 0 } }"
            ><v-icon dark left>mdi-plus</v-icon>Toevoegen</v-btn
          >
        </v-app-bar>
      </div>
      <div class="m-p-content">
        <v-data-table
          v-if="shipmentMethods.length"
          :hide-default-footer="shipmentMethods.length < 4"
          :headers="headers"
          :items="shipmentMethods"
          :options.sync="options"
          :server-items-length="shipmentMethodsLength"
          :loading="loading"
          @click:row="details"
          item-key="id"
        >
          <template v-slot:item.product_weight="{ item }">
            <v-chip small outlined>
              {{ item.product_weight }}
            </v-chip>
          </template>
          <template v-slot:item.updated_at="{ item }">
            {{ formatDate(item.updated_at) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon title="Verwijderen" @click.stop="destroy(item.id)">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
            <v-btn icon title="Wijzigen" @click.stop="details(item)">
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>
  </transition>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import ShipmentMethodService from "@/services/ShipmentMethodService";

export default {
  data() {
    return {
      shipmentMethods: null,
      shipmentMethodsLength: null,
      search: null,
      loading: true,
      options: {},
      headers: [
        {
          text: "Titel",
          align: "left",
          sortable: true,
          value: "title",
        },
        {
          text: "Productgewicht",
          align: "left",
          sortable: true,
          value: "product_weight",
        },
        {
          text: "Actief",
          align: "left",
          sortable: true,
          value: "active_title",
        },
        {
          text: "Delivery code",
          align: "left",
          sortable: true,
          value: "code_delivery",
        },
        {
          text: "Laatste Update",
          align: "left",
          sortable: true,
          value: "updated_at",
        },
        {
          text: "Acties",
          align: "left",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapState(["shipmentMethod"]),
  },
  watch: {
    options: {
      deep: true,
      handler() {
        if (this.options.itemsPerPage == -1) this.options.itemsPerPage = 1000;
        this.$store.dispatch("shipmentMethod/setOptions", this.options);
        this.index();
      },
    },
    search: function (newValue, oldValue) {
      if (!oldValue) return;
      this.doSearch();
    },
  },
  mounted() {
    this.options = _.cloneDeep(this.shipmentMethod.options);
    this.search = _.cloneDeep(this.shipmentMethod.search);
  },
  methods: {
    details(item) {
      this.$router.push({
        name: "ShipmentMethodDetails",
        params: { id: item.id },
      });
    },
    destroy(id) {
      this.$root.$confirm
        .open("Verwijderen", "Weet je het zeker?")
        .then((confirm) => {
          if (!confirm) return;
          ShipmentMethodService.destroy(id)
            .then(({ data }) => {
              this.notify(data.message);
              this.$store.dispatch("shipmentMethod/list");
              this.index();
            })
            .catch((err) => {
              this.notifyErr(err);
            });
        });
    },
    doSearch: _.debounce(function () {
      this.options.page = 1;
      this.$store.dispatch("shipmentMethod/setSearch", this.search);
      this.index();
    }, 500),
    index() {
      let data = _.cloneDeep(this.options);
      data.search = this.search;
      ShipmentMethodService.index(data).then(({ data }) => {
        this.shipmentMethods = data.data.shipmentMethods;
        this.shipmentMethodsLength = data.data.shipmentMethodsLength;
      });
      this.loading = false;
    },
  },
};
</script>
