import Vue from "vue";
import VueRouter from "vue-router";
import LayoutApp from "@/layouts/App.vue";
import LayoutNotAuthenticated from "@/layouts/NotAuthenticated.vue";
import Dashboard from "@/views/dashboard/Index.vue";
import NotFound from "@/views/NotFound.vue";
import UserLogin from "@/views/user/Login.vue";
import UserLogout from "@/views/user/Logout.vue";
import UserPasswordRequest from "@/views/user/PasswordRequest.vue";
import UserPassword from "@/views/user/Password.vue";
import SalesOrderIndex from "@/views/sales_order/Index.vue";
import ShipmentIndex from "@/views/shipment/Index.vue";
import ShipmentMethodIndex from "@/views/shipment_method/Index.vue";
import ShipmentMethodDetails from "@/views/shipment_method/Details.vue";
import EmailTemplateIndex from "@/views/email_template/Index.vue";
import EmailTemplateDetails from "@/views/email_template/Details.vue";
import ProductIndex from "@/views/product/Index.vue";
import ProductDetails from "@/views/product/Details.vue";
import JobIndex from "@/views/job/Index.vue";
import JobDetails from "@/views/job/Details.vue";
import UserIndex from "@/views/user/Index.vue";
import UserDetails from "@/views/user/Details.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/dashboard" },
  {
    path: "/inloggen",
    component: LayoutNotAuthenticated,
    children: [
      {
        name: "UserLogin",
        path: "",
        component: UserLogin,
      },
    ],
  },
  {
    path: "/uitloggen",
    component: LayoutNotAuthenticated,
    children: [
      {
        name: "UserLogout",
        path: "",
        component: UserLogout,
      },
    ],
  },
  {
    path: "/wachtwoord-wijzigen-aanvraag",
    component: LayoutNotAuthenticated,
    children: [
      {
        name: "UserPasswordRequest",
        path: "",
        component: UserPasswordRequest,
      },
    ],
  },
  {
    path: "/wachtwoord-wijzigen",
    component: LayoutNotAuthenticated,
    children: [
      {
        name: "UserPassword",
        path: "",
        component: UserPassword,
      },
    ],
  },
  {
    path: "/dashboard",
    component: LayoutApp,
    children: [
      {
        name: "Dashboard",
        path: "",
        component: Dashboard,
      },
    ],
  },
  {
    path: "/orders/:salesOrderId?",
    component: LayoutApp,
    children: [
      {
        name: "SalesOrderIndex",
        path: "",
        component: SalesOrderIndex,
        props: true,
      },
    ],
  },
  {
    path: "/zendingen",
    component: LayoutApp,
    children: [
      {
        name: "ShipmentIndex",
        path: "",
        component: ShipmentIndex,
        props: true,
      },
    ],
  },
  {
    path: "/product",
    component: LayoutApp,
    children: [
      {
        name: "ProductIndex",
        path: "",
        component: ProductIndex,
      },
    ],
  },
  {
    path: "/product/:id",
    component: LayoutApp,
    children: [
      {
        name: "ProductDetails",
        path: "",
        component: ProductDetails,
        props: true,
      },
    ],
  },
  {
    path: "/verzendmethoden",
    component: LayoutApp,
    children: [
      {
        name: "ShipmentMethodIndex",
        path: "",
        component: ShipmentMethodIndex,
        props: true,
      },
    ],
  },
  {
    path: "/verzendmethoden/:id",
    component: LayoutApp,
    children: [
      {
        name: "ShipmentMethodDetails",
        path: "",
        component: ShipmentMethodDetails,
        props: true,
      },
    ],
  },
  {
    path: "/email-templates",
    component: LayoutApp,
    children: [
      {
        name: "EmailTemplateIndex",
        path: "",
        component: EmailTemplateIndex,
      },
    ],
  },
  {
    path: "/email-templates/:id",
    component: LayoutApp,
    children: [
      {
        name: "EmailTemplateDetails",
        path: "",
        component: EmailTemplateDetails,
        props: true,
      },
    ],
  },
  {
    path: "/jobs",
    component: LayoutApp,
    children: [
      {
        name: "JobIndex",
        path: "",
        component: JobIndex,
      },
    ],
  },
  {
    path: "/jobs/:id",
    component: LayoutApp,
    children: [
      {
        name: "JobDetails",
        path: "",
        component: JobDetails,
        props: true,
      },
    ],
  },
  {
    path: "/gebruikers",
    component: LayoutApp,
    children: [
      {
        name: "UserIndex",
        path: "",
        component: UserIndex,
      },
    ],
  },
  {
    path: "/gebruiker/:id",
    component: LayoutApp,
    children: [
      {
        name: "UserDetails",
        path: "",
        component: UserDetails,
        props: true,
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: LayoutNotAuthenticated,
    children: [
      {
        name: "NotFound",
        path: "",
        component: NotFound,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
