import SalesOrderAllsolutionsStatusService from "@/services/SalesOrderAllsolutionsStatusService";

export const namespaced = true;

export const state = {
  items: [],
};

export const mutations = {
  INDEX(state, data) {
    state.items = data;
  },
};

export const actions = {
  index({ commit }) {
    SalesOrderAllsolutionsStatusService.index()
      .then(({ data }) => {
        commit("INDEX", data.salesOrderAllsolutionsStatuses);
      })
      .catch((error) => {
        let notification = {
          type: "error",
          message: error.message,
        };
        this.$store.dispatch("notification/add", notification, {
          root: true,
        });
      });
  },
};

export const getters = {
  title: (state) => (id) => {
    let items = state.items.filter((item) => item.id === id);
    return items.length ? items[0].title : "";
  },
};
