<template>
  <transition name="module-fade">
    <div class="module">
      <div class="m-primary">
        <div class="m-p-header">
          <v-toolbar flat dark color="primary">
            <v-toolbar-title>Dashboard </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </div>
        <div class="m-p-body">
          <div class="m-p-content pa-5">
            <v-container>
              <v-row>
                <v-col
                  ><v-card outlined>
                    <v-card-text
                      ><TotalNotShippedSalesOrders></TotalNotShippedSalesOrders>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  ><v-card outlined>
                    <v-card-text
                      ><TotalNotShippedProducts></TotalNotShippedProducts>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  ><v-card outlined>
                    <v-card-text
                      ><TotalSalesOrdersStatus
                        :salesOrderStatusId="2"
                        title="Orders vastgehouden"
                      ></TotalSalesOrdersStatus>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  ><v-card outlined>
                    <v-card-text
                      ><TotalSalesOrdersStatus
                        :salesOrderStatusId="3"
                        title="Orders op zicht"
                      ></TotalSalesOrdersStatus>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  ><v-card outlined>
                    <v-card-text
                      ><SalesOrderProducts></SalesOrderProducts>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  ><v-card outlined>
                    <v-card-text>
                      <ShipmentProducts></ShipmentProducts
                    ></v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  ><v-card outlined>
                    <v-card-text>
                      <ChartRevenue></ChartRevenue>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  ><v-card outlined>
                    <v-card-text><Shipments></Shipments> </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ChartRevenue from "@/views/chart/Revenue.vue";
import Shipments from "@/views/chart/Shipments.vue";
import ShipmentProducts from "@/views/chart/ShipmentProducts.vue";
import SalesOrderProducts from "@/views/chart/SalesOrderProducts.vue";
import TotalNotShippedSalesOrders from "@/views/chart/TotalNotShippedSalesOrders.vue";
import TotalNotShippedProducts from "@/views/chart/TotalNotShippedProducts.vue";
import TotalSalesOrdersStatus from "@/views/chart/TotalSalesOrdersStatus.vue";

export default {
  components: {
    TotalSalesOrdersStatus,
    TotalNotShippedProducts,
    TotalNotShippedSalesOrders,
    ShipmentProducts,
    Shipments,
    ChartRevenue,
    SalesOrderProducts,
  },
};
</script>
