<template>
  <div class="m-p-content">
    <v-toolbar flat color="#ffffff">
      <div>
        <v-toolbar-title>AllSolutions data</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <JobExecute
        v-if="salesOrder.can_export"
        :id="4"
        title="Synchroniseren"
        icon="mdi-database-export-outline"
        :salesOrder="salesOrder"
        @refresh="refresh"
      ></JobExecute>
    </v-toolbar>
    <v-container class="pa-4">
      <v-row>
        <v-col>
          <div class="mb-4">
            {{ formatDate(salesOrder.exported_at) }}
          </div>
          <vue-json-pretty :data="salesOrder.allsolutions_data" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import JobExecute from "@/views/job/components/Execute.vue";

export default {
  components: { JobExecute, VueJsonPretty },
  props: ["salesOrder"],
  methods: {
    refresh() {
      this.$store.dispatch("salesOrder/show", this.salesOrder.id);
    },
  },
};
</script>
