export const namespaced = true;

export const state = {
  items: [],
  item: null,
  options: {},
  search: null,
};

export const mutations = {
  SET_OPTIONS(state, data) {
    state.options = data;
  },
  SET_SEARCH(state, data) {
    state.search = data;
  },
};

export const actions = {
  setOptions({ commit }, data) {
    commit("SET_OPTIONS", data);
  },
  setSearch({ commit }, data) {
    commit("SET_SEARCH", data);
  },
};
