var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{style:({
    background: `center/cover url(${require('@/assets/bg_login.jpg')})`,
  }),attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":"","md4":""}},[_c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"ml-4"},[_vm._v("Wachtwoord wijzigen - Eduforce-orderbeheer")])],1),(_vm.status == 1)?_c('v-card-subtitle',{staticClass:"pr-8 pb-8 pl-8"},[_vm._v("Vul je e-mailadres in, dan ontvang je een e-mailbericht met daarin een link om je wachtwoord te wijzigen.")]):_vm._e(),(_vm.status == 2)?_c('v-card-subtitle',{staticClass:"pr-8 pb-8 pl-8"},[_c('Alert',{attrs:{"alert":{
              type: 'success',
              message:
                'Als je e-mailadres voorkomt in Eduforce-orderbeheer ontvang je een e-mailbericht met daarin een link om je wachtwoord te wijzigen.',
            }}})],1):_vm._e(),_c('v-card-text',{staticClass:"pr-8 pb-8 pl-8"},[_c('v-form',[_c('v-text-field',{staticClass:"mt-0",attrs:{"prepend-icon":"mdi-account","type":"text","label":"E-mailadres","error":_vm.form.user.fields.email.error,"error-messages":_vm.form.user.fields.email.error_messages,"disabled":_vm.form.user.fields.email.disabled,"outlined":"","dense":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-3",attrs:{"text":"","dark":"","outlined":"","rounded":"","color":"primary","to":{ name: 'UserLogin' }}},[_vm._v("Inloggen")]),(_vm.status == 1)?_c('v-btn',{attrs:{"dark":"","color":"primary","rounded":"","loading":_vm.form.user.submitting},on:{"click":function($event){$event.stopPropagation();return _vm.submit.apply(null, arguments)}}},[_vm._v("Wachtwoord wijzigen")]):_vm._e()],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }