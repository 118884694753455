<template>
  <transition name="module-fade">
    <div class="module" v-if="loaded">
      <div class="m-primary flex">
        <v-toolbar flat dark color="primary">
          <v-btn icon :to="{ name: 'ProductIndex' }">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>{{ product.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            v-if="id && false"
            class="ma-2"
            @click.stop="destroy(id)"
            outlined
            rounded
            color="white"
          >
            <v-icon left>mdi-delete-outline</v-icon>
            Verwijder
          </v-btn>
          <v-btn
            v-if="false"
            class="ma-2 primary--text"
            rounded
            color="white"
            :loading="form.product.submitting"
            v-on:click="submit"
            ><v-icon dark left>mdi-content-save</v-icon> Opslaan</v-btn
          >
        </v-toolbar>
        <v-tabs vertical v-model="tab" color="primary">
          <v-tab key="tab1">
            <v-icon class="mr-2">mdi-database-outline</v-icon>
            Gegevens
          </v-tab>
          <v-tab key="tab2">
            <v-icon class="mr-2">mdi-database-import-outline</v-icon>
            AllSolutions data
          </v-tab>
          <v-tab key="tab3">
            <v-icon class="mr-2">mdi-database-export-outline</v-icon>
            Magento data
          </v-tab>
          <v-tabs-items v-model="tab">
            <v-tab-item key="item1">
              <Edit :id="product.id"></Edit>
            </v-tab-item>
            <v-tab-item key="item2">
              <Import :product="product"></Import>
              ></v-tab-item
            >
            <v-tab-item key="item3">
              <Export :product="product"></Export
            ></v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </div>
    </div>
  </transition>
</template>

<script>
import ProductService from "@/services/ProductService";
import Edit from "@/views/product/Edit.vue";
import Import from "@/views/product/Import.vue";
import Export from "@/views/product/Export.vue";

export default {
  components: {
    Edit,
    Export,
    Import,
  },
  props: ["id"],
  data() {
    return {
      loaded: false,
      tab: null,
      product: {},
    };
  },
  mounted() {
    ProductService.edit(this.id)
      .then(({ data }) => {
        console.log("data", data);
        this.product = data.product;
        this.loaded = true;
      })
      .catch((err) => {
        this.notifyErr(err);
      });
  },
};
</script>
