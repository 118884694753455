<template>
  <div></div>
</template>

<script>
import UserService from "@/services/UserService";
export default {
  mounted() {
    UserService.logout()
      .then(({ data }) => {
        this.notify(data.message);
        this.$store
          .dispatch("user/set", { name: null, email: null })
          .then(() => {
            this.$router.push({ name: "UserLogin" });
          });
      })
      .catch((err) => {
        this.notifyErr(err);
      });
  },
};
</script>
