import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_HOST_API + "/sanctum",
  withCredentials: true,
  headers: {
    Accept: "application/json",
  },
});

export default {
  csrfCookie() {
    return apiClient.get("/csrf-cookie");
  },
};
