<template>
  <v-dialog
    v-model="open"
    hide-overlay
    attach=".v-main__wrap"
    v-if="shipment.item"
  >
    <v-card>
      <v-toolbar flat color="primary" dark>
        <v-btn icon @click="close">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <div class="ml-16" v-if="shipment.item.id">
          <Status :shipment="shipment.item"></Status>
        </div>
        <v-spacer></v-spacer>
        <Actions
          v-if="shipment.item.id"
          :item="shipment.item"
          @index="index"
        ></Actions>
        <v-btn
          class="ma-2 primary--text"
          rounded
          color="white"
          :loading="form.submitting"
          @click="save"
          ><v-icon dark left>mdi-content-save</v-icon> Opslaan</v-btn
        >
      </v-toolbar>
      <v-card-title class="pt-5">
        Hoe wil je het versturen?
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pt-2">
        <div class="d-flex">
          <v-card
            width="auto"
            class="method mr-4 pa-3"
            :class="{ 'method--selected': form.data.method_id == method.id }"
            @click="setMethod(method.id)"
            v-for="method in shipmentMethods"
            :key="`method-${method.id}`"
          >
            <div class="">
              {{ method.title }}
              <v-chip small outlined> {{ method.product_weight }} </v-chip>
            </div>
            <div v-if="false" class="" v-html="method.description"></div>
          </v-card>
        </div>
      </v-card-text>
      <v-card-title class="pt-3">Wat wil je versturen?</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Sku</th>
                <th class="text-left">Product</th>
                <th class="text-left">Verzendgewicht</th>
                <th class="text-left">Vasthouden tot</th>
                <th class="text-left">Besteld</th>
                <th class="text-left">Andere zendingen</th>
                <th class="text-left">Deze zending</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(product, index) in form.data.products"
                :key="`shipmentProduct${product.sales_order_product_id}`"
              >
                <td>{{ product.sku }}</td>
                <td>{{ product.name }}</td>
                <td>
                  <v-chip small outlined>
                    {{ product.shipment_weight }}
                  </v-chip>
                </td>
                <td>
                  <v-chip
                    small
                    outlined
                    color="orange"
                    v-if="product.available_from"
                  >
                    {{ formatDate(product.available_from) }}
                  </v-chip>
                </td>
                <td>{{ product.ordered }}</td>
                <td>{{ product.shipped }}</td>

                <td>
                  <v-text-field
                    style="width: 100px"
                    v-model="form.data.products[index].quantity"
                    outlined
                    dense
                    single-line
                    type="number"
                    :rules="[
                      (val) =>
                        (val >= 0 &&
                          val <= product.ordered - product.shipped) ||
                        `hi ha ho`,
                    ]"
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import ShipmentService from "@/services/ShipmentService";
import { mapState } from "vuex";
import Status from "@/views/shipment/components/Status.vue";
import Actions from "@/views/shipment/components/Actions.vue";

export default {
  components: { Actions, Status },
  props: ["open"],
  data() {
    return {
      form: {
        submitting: false,
        data: {
          sales_order_id: null,
          shipment_id: null,
          method_id: null,
          products: [],
        },
      },
    };
  },
  computed: {
    ...mapState(["shipment", "shipmentMethod"]),
    title() {
      if (this.shipment.item.id) {
        return this.shipment.item.number;
      }
      return "Nieuwe zending";
    },
    shipmentMethods() {
      return this.shipmentMethod.list.filter(
        (m) => m.country_id === this.shipment.item.country_id
      );
    },
  },
  methods: {
    index() {
      this.$store.dispatch("shipment/edit", {
        id: this.shipment.item.id,
        sales_order_id: 0,
      });
    },
    close() {
      this.$emit("close");
    },
    save() {
      this.form.submitting = true;
      if (this.shipment.item.id) {
        ShipmentService.update(this.shipment.item.id, this.form.data)
          .then(({ data }) => {
            this.notify(data.message);
            this.$store.dispatch(
              "salesOrder/show",
              this.shipment.item.sales_order_id
            );
            this.form.submitting = false;
            this.close();
          })
          .catch((err) => {
            this.form.submitting = false;
            this.notifyErr(err);
          });
      } else {
        ShipmentService.store(this.form.data)
          .then(({ data }) => {
            this.notify(data.message);
            this.$store.dispatch(
              "salesOrder/show",
              this.shipment.item.sales_order_id
            );
            this.form.submitting = false;
            this.close();
          })
          .catch((err) => {
            this.form.submitting = false;
            this.notifyErr(err);
          });
      }
    },
    setMethod(id) {
      this.form.data.method_id = id;
    },
  },
  mounted() {
    console.log("shipment/Edit mounted()");
    let shipment = _.cloneDeep(this.shipment.item);
    this.form.data.shipment_id = shipment.id;
    this.form.data.sales_order_id = shipment.sales_order_id;
    this.form.data.method_id = shipment.method_id;
    this.form.data.products = shipment.form_products;
  },
};
</script>

<style scoped lang="scss">
.method {
  border: 2px solid rgba(0, 0, 0, 0.12) !important;

  &--selected {
    border: 2px solid #333333 !important;
  }
}
</style>
