import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_HOST_API + "/api/shipment-method",
  withCredentials: true,
  headers: {
    Accept: "application/json",
  },
});

// apiClient.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (error.response.data.status == 401) {
//       //
//     }
//     return Promise.reject(error);
//   }
// );

export default {
  index(data) {
    return apiClient.get(null, { params: data });
  },
  list() {
    return apiClient.get("/list");
  },
  create() {
    return apiClient.get("/create");
  },
  store(data) {
    return apiClient.post(null, data);
  },
  show(id) {
    return apiClient.get("/" + id);
  },
  edit(id) {
    return apiClient.get("/" + id + "/edit");
  },
  update(id, data) {
    return apiClient.put("/" + id, data);
  },
  destroy(id) {
    return apiClient.delete("/" + id);
  },
};
