import ProductService from "@/services/ProductService";

export const namespaced = true;

export const state = {
  items: [],
  totalItems: 0,
  item: null,
  options: {},
  search: null,
};

export const mutations = {
  SET_OPTIONS(state, data) {
    state.options = data;
  },
  SET_SEARCH(state, data) {
    state.search = data;
  },
  SET_ITEMS(state, data) {
    state.items = data;
  },
  SET_TOTAL_ITEMS(state, data) {
    state.totalItems = data;
  },
};

export const actions = {
  index({ commit, state }) {
    let data = {
      ...state.options,
      search: state.search,
    };
    return ProductService.index(data).then(({ data }) => {
      commit("SET_ITEMS", data.products);
      commit("SET_TOTAL_ITEMS", data.recordsTotal);
    });
  },
  setOptions({ commit }, data) {
    commit("SET_OPTIONS", data);
  },
  setSearch({ commit }, data) {
    commit("SET_SEARCH", data);
  },
};
