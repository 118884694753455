<template>
  <div style="width: 200px" class="mr-4">
    <v-text-field
      rounded
      hide-details
      v-model="input"
      label="Verzendlabel PDF"
      required
      outlined
      dense
      :append-icon="append"
    ></v-text-field>
  </div>
</template>

<script>
import ShipmentService from "@/services/ShipmentService";

export default {
  data() {
    return {
      input: "",
      submitting: false,
    };
  },
  watch: {
    input: function (newValue) {
      if (newValue.length === 6) {
        this.downloadLabel(newValue);
      }
    },
  },
  methods: {
    downloadLabel(increment_id) {
      if (this.submitting) return;
      this.submitting = true;
      ShipmentService.labelPdf(parseInt(increment_id))
        .then((response) => {
          const blob = this.b64toBlob(response.data, "application/pdf");
          this.downloadBlob(
            blob,
            "application/pdf",
            "label-" + increment_id + ".pdf"
          );
          setTimeout(() => {
            this.submitting = false;
            this.input = "";
          }, 250);
        })
        .catch((err) => {
          this.notifyErr(err);
          this.submitting = false;
        });
    },
  },
  computed: {
    append() {
      return this.submitting ? "mdi-download-outline" : "";
    },
  },
};
</script>
