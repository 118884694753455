<template>
  <div class="m-p-content">
    <v-toolbar flat color="#ffffff">
      <div>
        <v-toolbar-title>Magento data</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container class="pa-4">
      <v-row>
        <v-col>
          <div class="mb-4">
            {{ formatDate(product.export_updated_at) }}
          </div>
          <vue-json-pretty :data="product.log" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";

export default {
  components: { VueJsonPretty },
  props: ["product"],
};
</script>
