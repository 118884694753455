import ShipmentMethodCountryService from "@/services/ShipmentMethodCountryService";

export const namespaced = true;

export const state = {
  list: [],
  items: [],
  item: null,
  options: {},
  search: null,
};

export const mutations = {
  LIST(state, data) {
    state.list = data;
  },
  SET_OPTIONS(state, data) {
    state.options = data;
  },
  SET_SEARCH(state, data) {
    state.search = data;
  },
};

export const actions = {
  list({ commit }) {
    ShipmentMethodCountryService.list().then((response) => {
      commit("LIST", response.data.shipmentMethodCountries);
    });
  },
  setOptions({ commit }, data) {
    commit("SET_OPTIONS", data);
  },
  setSearch({ commit }, data) {
    commit("SET_SEARCH", data);
  },
};
