<template>
  <div>
    <div class="mb-10">
      <v-textarea
        v-model="comment.comments"
        label="Nieuwe notitie"
        required
        :error="form.comment.fields.comments.error"
        :error-messages="form.comment.fields.comments.error_messages"
        outlined
        rows="4"
        row-height="20"
      ></v-textarea>
      <v-row>
        <v-col>
          <v-switch
            label="Klant informeren"
            style="display: inline"
            v-model="comment.isCustomerNotified"
          ></v-switch>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            class="primary--text"
            rounded
            color="white"
            :loading="form.comment.submitting"
            @click="submit"
            ><v-icon dark left>mdi-content-save</v-icon> Opslaan</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <div class="mb-n6">
      <div
        class="mb-6"
        v-for="(comment, index) in comments"
        :key="`comment${index}`"
      >
        <div class="d-flex text-subtitle-2">
          <span>{{ formatDateTimeLong(comment.created_at) }}</span
          ><v-spacer></v-spacer
          ><span
            >klant
            {{ comment.is_customer_notified ? " is " : " niet " }}
            geïnformeerd</span
          >
        </div>
        <div>{{ comment.comment }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import SalesOrderService from "@/services/SalesOrderService";

export default {
  props: ["salesOrderId"],
  data() {
    return {
      comments: [],
      comment: {
        comments: "",
        isCustomerNotified: false,
      },
      form: {
        comment: {
          submitting: false,
          fields: {
            comments: { error: false, error_messages: [] },
            isCustomerNotified: { error: false, error_messages: [] },
          },
        },
      },
    };
  },
  watch: {
    salesOrderId: {
      handler() {
        this.get();
      },
    },
    search: function (newValue, oldValue) {
      if (!oldValue) return;
      this.doSearch();
    },
  },
  methods: {
    get() {
      SalesOrderService.comments(this.salesOrderId).then(({ data }) => {
        this.comments = data.items.reverse();
      });
    },
    submit() {
      this.form.comment.submitting = true;
      let formFields = this.form.comment.fields;
      this.resetValidationErrors(formFields);
      SalesOrderService.addComment(this.salesOrderId, this.comment)
        .then(({ data }) => {
          this.comment.comments = "";
          this.notify(data.message);
          this.get();
          this.form.comment.submitting = false;
        })
        .catch((err) => {
          this.notifyErr(err);
          if (err.response.status === 422) {
            this.showValidationErrors(formFields, err.response.data.errors);
          }
          this.form.comment.submitting = false;
        });
    },
  },
};
</script>
