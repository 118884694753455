<template>
  <alert :alert="notification"></alert>
</template>

<script>
import { mapActions } from "vuex";
import Alert from "./Alert.vue";

export default {
  components: { Alert },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
    };
  },
  mounted() {
    this.timeout = setTimeout(() => this.remove(this.notification), 8000);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: mapActions("notification", ["remove"]),
};
</script>
