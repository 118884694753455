<template>
  <div v-if="item">
    <v-toolbar color="primary" dark flat>
      <v-btn icon @click="close">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ item.increment_id }} </v-toolbar-title>
      <div class="ml-16">
        <Status></Status>
      </div>
      <div class="ml-16">
        <ShipmentStatus></ShipmentStatus>
      </div>
      <div class="ml-16">
        <AllsolutionsStatus></AllsolutionsStatus>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        icon
        :disabled="salesOrder.prevItemId === null"
        :to="{
          name: 'SalesOrderIndex',
          params: { salesOrderId: salesOrder.prevItemId },
        }"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn
        icon
        :disabled="salesOrder.nextItemId === null"
        :to="{
          name: 'SalesOrderIndex',
          params: { salesOrderId: salesOrder.nextItemId },
        }"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-toolbar>
    <v-tabs vertical v-model="tab">
      <v-tab key="tab1">
        <v-icon class="mr-2">mdi-information-outline</v-icon>
        Info
      </v-tab>
      <v-tab key="tab2">
        <v-icon class="mr-2">mdi-cart-outline</v-icon>
        <v-badge :color="shipmentsStatusColor" :content="item.quantity_ordered">
          Producten
        </v-badge>
        <v-chip v-if="false" x-small outlined class="ml-2"
          >{{ item.quantity_ordered }}
        </v-chip>
      </v-tab>
      <v-tab key="tab3">
        <v-icon class="mr-2">mdi-truck-cargo-container</v-icon>
        <v-badge
          :color="shipmentsStatusColor"
          :content="shipmentLength.toString()"
        >
          Zendingen
        </v-badge>
      </v-tab>
      <v-tab key="tab4">
        <v-icon class="mr-2">mdi-database-import-outline</v-icon>
        Magento data
      </v-tab>
      <v-tab key="tab5">
        <v-icon class="mr-2">mdi-database-export-outline</v-icon>
        AllSolutions data
      </v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item key="item1">
          <Info :salesOrder="item" />
        </v-tab-item>
        <v-tab-item key="item2">
          <Products :salesOrder="item" />
        </v-tab-item>
        <v-tab-item key="item3">
          <ShipmentIndex :salesOrder="item"></ShipmentIndex>
        </v-tab-item>
        <v-tab-item key="item4">
          <Import :sales-order="item"></Import
        ></v-tab-item>
        <v-tab-item key="item5">
          <Export :sales-order="item"></Export
        ></v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Info from "./Info";
import Products from "./Products";
import ShipmentIndex from "@/views/shipment/Index";
import Status from "./components/Status";
import ShipmentStatus from "./components/ShipmentStatus";
import AllsolutionsStatus from "./components/AllsolutionsStatus";
import Import from "@/views/sales_order/Import.vue";
import Export from "@/views/sales_order/Export.vue";

export default {
  props: ["salesOrderId"],
  components: {
    Import,
    Export,
    Info,
    Products,
    ShipmentIndex,
    Status,
    ShipmentStatus,
    AllsolutionsStatus,
  },
  data() {
    return {
      tab: null,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    show(id) {
      if (!id) return;
      this.$store.dispatch("salesOrder/show", id);
    },
  },
  computed: {
    ...mapState(["salesOrder"]),
    item() {
      return this.salesOrder.item;
    },
    shipmentLength() {
      return this.salesOrder.item.shipments
        ? this.salesOrder.item.shipments.length
        : 0;
    },
    shipmentsStatusColor() {
      if (this.item.quantity_ordered < 1) {
        return "green";
      } else if (this.item.quantity_ordered == this.item.quantity_shipped) {
        return "green";
      } else if (this.item.quantity_ordered > this.item.quantity_shipped) {
        if (this.item.quantity_shipped) {
          return "orange";
        } else {
          return "blue";
        }
      }
      return "orange";
    },
  },
  watch: {
    salesOrderId: function (val) {
      this.show(val);
    },
  },
  mounted() {
    console.log("Show.vue mounted()", this.salesOrderId);
    this.show(this.salesOrderId);
  },
};
</script>
