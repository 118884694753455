<template>
  <transition name="module-fade">
    <div class="module" v-if="loaded">
      <div class="m-primary">
        <div class="m-p-header">
          <v-toolbar flat dark color="primary">
            <v-btn icon :to="{ name: 'ShipmentMethodIndex' }">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ shipmentMethod.title }} </v-toolbar-title>
            <v-switch
              class="mt-6 ml-6"
              v-model="shipmentMethod.active"
            ></v-switch>
            <v-spacer></v-spacer>
            <v-btn
              v-if="false"
              class="ma-2"
              :to="{ name: 'ShipmentMethodIndex' }"
              outlined
              rounded
              color="white"
            >
              <v-icon left>mdi-arrow-left</v-icon>
              Terug
            </v-btn>
            <v-btn
              v-if="id"
              class="ma-2"
              @click.stop="destroy(id)"
              outlined
              rounded
              color="white"
            >
              <v-icon left>mdi-delete-outline</v-icon>
              Verwijder
            </v-btn>
            <v-btn
              class="ma-2 primary--text"
              rounded
              color="white"
              :loading="form.shipmentMethod.submitting"
              v-on:click="submit"
              ><v-icon dark left>mdi-content-save</v-icon> Opslaan</v-btn
            >
          </v-toolbar>
        </div>
        <div class="m-p-body">
          <div class="m-p-content pa-5">
            <v-card outlined class="pa-4">
              <v-container class="shipmentMethod-edit">
                <v-row>
                  <v-col class="md-6">
                    <v-text-field
                      v-model="shipmentMethod.title"
                      label="Titel"
                      required
                      :error="form.shipmentMethod.fields.title.error"
                      :error-messages="
                        form.shipmentMethod.fields.title.error_messages
                      "
                      outlined
                      dense
                    ></v-text-field>
                    <v-text-field
                      v-model="shipmentMethod.code_delivery"
                      label="PostNL code_delivery"
                      required
                      :error="form.shipmentMethod.fields.code_delivery.error"
                      :error-messages="
                        form.shipmentMethod.fields.code_delivery.error_messages
                      "
                      outlined
                      dense
                    ></v-text-field>
                    <v-text-field
                      v-model="shipmentMethod.product_weight"
                      label="Productgewicht"
                      required
                      :error="form.shipmentMethod.fields.product_weight.error"
                      :error-messages="
                        form.shipmentMethod.fields.product_weight.error_messages
                      "
                      outlined
                      dense
                      type="number"
                      :min="0"
                      :max="100"
                    ></v-text-field>
                    <v-select
                      :items="shipmentMethodCountry.list"
                      item-text="title"
                      item-value="id"
                      v-model="shipmentMethod.country_id"
                      label="Land"
                      required
                      :error="form.shipmentMethod.fields.country_id.error"
                      :error-messages="
                        form.shipmentMethod.fields.country_id.error_messages
                      "
                      outlined
                      dense
                    ></v-select>
                    <v-textarea
                      v-model="shipmentMethod.description"
                      label="Omschrijving"
                      required
                      :error="form.shipmentMethod.fields.description.error"
                      :error-messages="
                        form.shipmentMethod.fields.description.error_messages
                      "
                      outlined
                      dense
                    ></v-textarea>
                  </v-col>
                  <v-col class="md-6"> </v-col>
                </v-row>
              </v-container>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
import ShipmentMethodService from "@/services/ShipmentMethodService";

export default {
  props: ["id"],
  data() {
    return {
      loaded: false,
      shipmentMethod: {
        active: null,
        country_id: null,
        code_delivery: null,
        title: null,
        description: null,
        product_weight: null,
      },
      form: {
        shipmentMethod: {
          submitting: false,
          fields: {
            active: { error: false, error_messages: [] },
            country_id: { error: false, error_messages: [] },
            code_delivery: { error: false, error_messages: [] },
            title: { error: false, error_messages: [] },
            description: { error: false, error_messages: [] },
            product_weight: { error: false, error_messages: [] },
          },
        },
      },
    };
  },
  computed: {
    ...mapState(["shipmentMethodCountry"]),
  },
  methods: {
    submit() {
      this.form.shipmentMethod.submitting = true;
      let formFields = this.form.shipmentMethod.fields;
      this.resetValidationErrors(formFields);
      const apiCall = this.id
        ? ShipmentMethodService.update(this.id, this.shipmentMethod)
        : ShipmentMethodService.store(this.shipmentMethod);
      apiCall
        .then(({ data }) => {
          this.notify(data.message);
          this.$router.push({ name: "ShipmentMethodIndex" });
          this.form.shipmentMethod.submitting = false;
        })
        .catch((err) => {
          this.notifyErr(err);
          if (err.response.status === 422) {
            this.showValidationErrors(formFields, err.response.data.errors);
          }
          this.form.shipmentMethod.submitting = false;
        });
    },
    destroy(id) {
      this.$root.$confirm
        .open("Verwijderen", "Weet je het zeker?")
        .then((confirm) => {
          if (!confirm) return;
          ShipmentMethodService.destroy(id)
            .then(({ data }) => {
              this.notify(data.message);
              this.$router.push({ name: "ShipmentMethodIndex" });
              this.$store.dispatch("shipmentMethod/list");
            })
            .catch((err) => {
              this.notifyErr(err);
            });
        });
    },
  },
  mounted() {
    if (this.id) {
      ShipmentMethodService.edit(this.id).then(({ data }) => {
        this.shipmentMethod = data.shipmentMethod;
        this.loaded = true;
      });
    } else {
      ShipmentMethodService.create().then(({ data }) => {
        this.shipmentMethod = data.shipmentMethod;
        this.loaded = true;
      });
    }
  },
};
</script>
