import ShipmentService from "@/services/ShipmentService";

export const namespaced = true;

export const state = {
  items: [],
  totalItems: 0,
  item: {},
  options: {},
  search: "",
};

export const mutations = {
  SET_OPTIONS(state, data) {
    state.options = data;
  },
  SET_SEARCH(state, data) {
    state.search = data;
  },
  SET_ITEMS(state, data) {
    state.items = data;
  },
  SET_ITEM(state, data) {
    state.item = data;
  },
  SET_TOTAL_ITEMS(state, data) {
    state.totalItems = data;
  },
  STORE(state, data) {
    state.Shipment = data;
  },
};

export const actions = {
  index({ commit, state }, salesOrderId) {
    let data = {
      ...state.options,
      search: state.search,
      sales_order_id: salesOrderId,
    };
    return ShipmentService.index(data).then(({ data }) => {
      commit("SET_ITEMS", data.shipments);
      commit("SET_TOTAL_ITEMS", data.recordsTotal);
    });
  },
  setOptions({ commit }, data) {
    commit("SET_OPTIONS", data);
  },
  setSearch({ commit }, data) {
    commit("SET_SEARCH", data);
  },
  store({ commit, dispatch }, data) {
    ShipmentService.store(data).then(({ data }) => {
      commit("STORE", data);
      dispatch("shipment/show", data.order_id, {
        root: true,
      });
    });
  },
  updateStatus({ dispatch }, data) {
    return ShipmentService.updateStatus(data.id, {
      status_id: data.status_id,
    })
      .then(({ data }) => {
        dispatch("salesOrder/show", data.shipment.sales_order_id, {
          root: true,
        });
        dispatch(
          "shipment/edit",
          {
            id: data.shipment.id,
            sales_order_id: data.shipment.sales_order_id,
          },
          { root: true }
        );
      })
      .catch((err) => {
        dispatch(
          "notification/add",
          {
            type: "error",
            message: err,
          },
          {
            root: true,
          }
        );
      });
  },
  edit({ commit, dispatch }, data) {
    return ShipmentService.edit(data)
      .then(({ data }) => {
        commit("SET_ITEM", data.shipment);
      })
      .catch((err) => {
        dispatch(
          "notification/add",
          {
            type: "error",
            message: err,
          },
          {
            root: true,
          }
        );
      });
  },
};
